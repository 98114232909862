import Select from 'react-select';
import DatePicker from 'react-datepicker';

export const DateInput = ({
  options,
  selectValue,
  onSelectChange,
  inputValue,
  onInputChange,
  id,
}) => {
  return (
    <>
      <Select
        options={options}
        value={selectValue}
        onChange={(option) => onSelectChange(option)}
        className="w-full p-2.5"
        menuPosition="fixed"
      />
      <div className="m-2.5" id={id} tabIndex="0">
        <DatePicker
          portalId="root"
          popperClassName="ag-custom-component-popup datepicker-filter-popper"
          selected={inputValue}
          fixedHeight
          dateFormat="yyyy-MM-dd"
          className="w-full py-1.5 pl-2.5 text-[13px] border-[#ccc] rounded"
          onChange={(date) => {
            onInputChange(date);
            document.getElementById(id).focus();
          }}
        />
      </div>
    </>
  );
};
