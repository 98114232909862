import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ResetFilterButton } from './ResetFilterButton';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';

export default forwardRef((props, ref) => {
  const [filterValues, setFilterValues] = useState([]);
  const [checkboxFocused, setCheckboxFocused] = useState(null);

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        const value = props.valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });

        return filterPass(value, filterValues);
      },

      isFilterActive() {
        return filterValues.length;
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          values: filterValues.map((v) => v.value),
          valueLabels: filterValues.map((v) => v.label),
          type: 'checkbox',
          label: props.colDef.headerName,
        };
      },

      setModel(model) {
        setFilterValues(
          model === null || model === undefined ? [] : model.values
        );
      },
    };
  });

  const filterPass = (value, filterValue) => {
    return filterValue.some((f) => f.value === value);
  };

  const resetFilter = () => {
    if (filterValues) {
      setFilterValues([]);
    }
    setCheckboxFocused(null);
  };

  const handleChange = (item) => {
    const element = filterValues.find((v) => v.value === item.value);
    element
      ? setFilterValues(filterValues.filter((v) => v.value !== item.value))
      : setFilterValues([...filterValues, item]);
    setCheckboxFocused(item);
  };

  const Checkbox = ({ item }) => {
    return (
      <div className="flex-auto">
        <input
          autoFocus={item === checkboxFocused}
          type="checkbox"
          value={item}
          checked={filterValues && filterValues.includes(item)}
          onChange={() => handleChange(item)}
        />
        <span className="ml-2">{item.label}</span>
      </div>
    );
  };

  return (
    <div
      id="table-checkbox-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, props)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <div className="flex p-2.5">
        {props.colDef.filterParams.values.map((item) => (
          <Checkbox key={item.value} item={item} />
        ))}
      </div>
    </div>
  );
});
