import { axiosSecureInstance } from './apiSecure';

export async function getPipelineStatuses() {
  const data = await axiosSecureInstance.get('/verification_pipeline_statuses');
  return data;
}

export async function createPipelineStatus(data) {
  return await axiosSecureInstance.post(
    '/verification_pipeline_statuses',
    data
  );
}

export async function updatePipelineStatus(pipelineStatusId, params) {
  return await axiosSecureInstance.put(
    `/verification_pipeline_statuses/${pipelineStatusId}`,
    params
  );
}

export async function deletePipelineStatus(pipelineStatusId) {
  return await axiosSecureInstance.delete(
    `/verification_pipeline_statuses/${pipelineStatusId}`
  );
}
