import { createSlice } from '@reduxjs/toolkit';
import { loadFullGroups } from '../groups/groupsActions';
import { logout, resetState } from '../user/userActions';

const initialState = {
  loadingGroups: false,
  loadingGroupsError: null,
  groupsDetails: null,
};
export const groupsSlice = createSlice({
  name: 'groups',
  initialState: initialState,
  extraReducers: {
    [loadFullGroups.pending]: (state) => {
      state.loadingGroups = true;
      state.loadingGroupsError = null;
    },
    [loadFullGroups.fulfilled]: (state, { payload }) => {
      state.loadingGroups = false;
      state.loadingGroupsError = null;
      state.groupsDetails = payload;
    },
    [loadFullGroups.rejected]: (state, { payload }) => {
      state.loadingGroups = false;
      state.loadingGroupsError = payload;
    },
    [logout]: () => initialState,
    [resetState]: () => initialState,
  },
});

export default groupsSlice.reducer;
