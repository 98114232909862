import Mappings from './Mappings';
import AdminMappingPanel from './AdminMappingPanel';

export default function AdminMapping() {
  return (
    <div id="mappings" className="flex flex-col h-full flex-grow-1">
      <AdminMappingPanel />
      <Mappings />
    </div>
  );
}
