export const getExcludedResourceConfirmationText = (
  resourceType,
  resourceId,
  resourceName,
  descendantsLength,
  action
) => {
  return (
    <>
      {resourceType === 'Practice'
        ? `This will ${action} the codes for the practice `
        : `This will ${action} the codes for practices contained by the practice group `}
      <b>
        [{resourceId}] {resourceName}
      </b>
      {descendantsLength > 0 && (
        <>
          {' '}
          and <b>{descendantsLength}</b> child group
          {descendantsLength > 1 ? 's' : ''}
        </>
      )}
      {action === 'allow' && <> to be included</>}
      {' in all the Custom Jobs.'}
    </>
  );
};
