import { axiosSecureInstance } from './apiSecure';

export async function getUserConfigurations() {
  return await axiosSecureInstance.get('/user_configurations');
}

export async function createUserConfiguration(data) {
  return await axiosSecureInstance.post('/user_configurations', data);
}

export async function updateUserConfiguration(configId, data) {
  return await axiosSecureInstance.put(
    `/user_configurations/${configId}`,
    data
  );
}

export async function deleteUserConfiguration(configId) {
  return await axiosSecureInstance.delete(`/user_configurations/${configId}`);
}
