import qs from 'qs';
import { axiosSecureInstance } from './apiSecure';
import { review_status } from '../constants/constants';

const paramsSerializer = (params) => {
  return qs.stringify(params, {
    arrayFormat: 'brackets',
    strictNullHandling: true,
  });
};

export async function getCodeMappings(params) {
  const config = {
    params: params,
    paramsSerializer,
  };

  let mappings = await axiosSecureInstance.get('/admin/mapping', config);
  return mappings.data.resources;
}

export async function getJobCodeMappings(jobId) {
  let mappings = await axiosSecureInstance.get(`/mappings/${jobId}`);
  const mappingsData = Array.isArray(mappings.data) ? mappings.data : [];
  const mappedMappingsData = mappingsData.map((item) => {
    const mappedReviewStatus = review_status.values_by_id[item.review_status];
    return {
      ...item,
      review_status: mappedReviewStatus,
    };
  });
  return mappedMappingsData;
}

export async function getCodeMappingsCount(params) {
  const config = {
    params: params,
    paramsSerializer,
  };

  let codeCount = await axiosSecureInstance.get(
    '/admin/mapping/code_count',
    config
  );

  return codeCount.data.code_count;
}

export async function batchUpdateCodeTagsAdd(updates) {
  let response = await axiosSecureInstance.post(
    '/admin/add_code_tags',
    updates
  );
  return response.data.resources;
}

export async function batchUpdateJobCodeTagsAdd(updates, jobId) {
  let response = await axiosSecureInstance.post(
    `/mappings/${jobId}/add_code_tags`,
    updates
  );
  return response.data;
}

export async function batchUpdateCodeTagsRemove(updates) {
  let response = await axiosSecureInstance.post(
    '/admin/remove_code_tags',
    updates
  );
  return response.data.resources;
}

export async function batchUpdateJobCodeTagsRemove(updates, jobId) {
  let response = await axiosSecureInstance.post(
    `/mappings/${jobId}/remove_code_tags`,
    updates
  );
  return response.data;
}

export async function batchUpdateCodeTagsReplace(updates) {
  let response = await axiosSecureInstance.post(
    '/admin/replace_code_tags',
    updates
  );
  return response.data.resources;
}

export async function batchUpdateJobCodeTagsReplace(updates, jobId) {
  let response = await axiosSecureInstance.post(
    `/mappings/${jobId}/replace_code_tags`,
    updates
  );
  return response.data;
}

export async function updateCodeMappings(updateParams) {
  let response = await axiosSecureInstance.put('/admin/mapping', updateParams);
  return response.data.resources;
}

export async function updateJobCodeMappings(updateParams, jobId) {
  let response = await axiosSecureInstance.put(
    `/mappings/${jobId}`,
    updateParams
  );
  return response.data;
}

export async function getUnverifiedCodes(jobId) {
  let response = await axiosSecureInstance.get(
    `/mappings/${jobId}/unverified_codes`
  );
  return response.data;
}

export async function getCodeTags() {
  return await axiosSecureInstance.get('/code_tags');
}

export async function getRevenueCategories() {
  return await axiosSecureInstance.get('/revenue_categories');
}

export async function getPractices() {
  return await axiosSecureInstance.get('/practices');
}

export async function getGroups() {
  return await axiosSecureInstance.get('/groups');
}

export async function updateTableRow(row) {
  return await axiosSecureInstance.put('/admin/mapping', row);
}

export async function updateJobTableRow(row, jobId) {
  return await axiosSecureInstance.put(`/mappings/${jobId}`, row);
}

export async function downloadCsv(params) {
  const config = {
    responseType: 'text',
    params: params,
    paramsSerializer,
  };
  return await axiosSecureInstance.get('/admin/mapping/download_csv', config);
}
