import { createSlice } from '@reduxjs/toolkit';
import {
  addPipelineStatus,
  editPipelineStatus,
  loadPipelineStatuses,
  removePipelineStatus,
} from './pipelineStatusActions';
import { logout, resetState } from '../user/userActions';
import { getPipelinestatusesForSelect } from '../../helpers/selectHelper';

const initialState = {
  pipelineStatuses: null,
  pipelineStatusesForSelect: [],
  loadingPipelineStatuses: false,
  loadingPipelineStatusesError: null,
};

export const pipelineStatusSlice = createSlice({
  name: 'pipelineStatus',
  initialState: initialState,
  extraReducers: {
    [loadPipelineStatuses.pending]: (state) => {
      state.loadingPipelineStatuses = true;
      state.loadingPipelineStatusesError = null;
    },
    [loadPipelineStatuses.fulfilled]: (state, { payload }) => {
      state.loadingPipelineStatuses = false;
      state.loadingPipelineStatusesError = null;
      state.pipelineStatuses = payload;
      state.pipelineStatusesForSelect = getPipelinestatusesForSelect(payload);
    },
    [loadPipelineStatuses.rejected]: (state, { payload }) => {
      state.loadingPipelineStatuses = false;
      state.loadingPipelineStatusesError = payload;
    },
    [addPipelineStatus.fulfilled]: (state, { payload }) => {
      state.pipelineStatuses.unshift(payload);
      state.pipelineStatusesForSelect.unshift({
        value: payload.status_name,
        label: payload.status_name,
        id: payload.id,
      });
    },
    [editPipelineStatus.fulfilled]: (state, { payload }) => {
      const index = state.pipelineStatuses.findIndex(
        (status) => status.id === payload.id
      );
      state.pipelineStatuses[index] = payload;
      state.pipelineStatusesForSelect[index] = {
        value: payload.status_name,
        label: payload.status_name,
        id: payload.id,
      };
    },
    [removePipelineStatus.fulfilled]: (state, { payload }) => {
      state.pipelineStatuses = state.pipelineStatuses.filter(
        (status) => status.id !== payload
      );
      state.pipelineStatusesForSelect = state.pipelineStatusesForSelect.filter(
        (status) => status.id !== payload
      );
    },
    [logout]: () => initialState,
    [resetState]: () => initialState,
  },
});

export default pipelineStatusSlice.reducer;
