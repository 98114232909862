import { createSlice } from '@reduxjs/toolkit';
import {
  loadUserTableConfigurations,
  addUserTableConfiguration,
  editUserTableConfiguration,
  removeUserTableConfiguration,
} from './userConfigActions';
import { logout, resetState } from '../user/userActions';
import { mapUserTableConfiguration } from '../../helpers/userHelper';

const initialState = {
  userTableConfigurations: null,
  activeUserTableConfiguration: null,
  loadingUserTableConfigurationsError: null,
};

export const userConfigSlice = createSlice({
  name: 'userConfig',
  initialState: initialState,
  extraReducers: {
    [loadUserTableConfigurations.pending]: (state) => {
      state.loadingUserTableConfigurationsError = null;
    },
    [loadUserTableConfigurations.fulfilled]: (state, { payload }) => {
      state.userTableConfigurations = payload.map(mapUserTableConfiguration);
      const activeConfig = payload.find((config) => config.active);
      if (activeConfig) {
        state.activeUserTableConfiguration =
          mapUserTableConfiguration(activeConfig);
      }
      state.loadingUserTableConfigurationsError = null;
    },
    [loadUserTableConfigurations.rejected]: (state, { payload }) => {
      state.userTableConfigurations = null;
      state.activeUserTableConfiguration = null;
      state.loadingUserTableConfigurationsError = payload;
    },
    [addUserTableConfiguration.fulfilled]: (state, { payload }) => {
      state.userTableConfigurations.push(mapUserTableConfiguration(payload));
      state.activeUserTableConfiguration = mapUserTableConfiguration(payload);
    },
    [editUserTableConfiguration.fulfilled]: (state, { payload }) => {
      const index = state.userTableConfigurations.findIndex(
        (config) => config.id === payload.id
      );
      state.userTableConfigurations[index] = mapUserTableConfiguration(payload);
      state.activeUserTableConfiguration = payload.active
        ? mapUserTableConfiguration(payload)
        : null;
    },
    [removeUserTableConfiguration.fulfilled]: (state, { payload }) => {
      state.userTableConfigurations = state.userTableConfigurations.filter(
        (config) => config.id !== payload
      );
      if (state.activeUserTableConfiguration?.id === payload) {
        state.activeUserTableConfiguration = null;
      }
    },
    [logout]: () => initialState,
    [resetState]: () => initialState,
  },
});

export default userConfigSlice.reducer;
