export default function ToggleButton({
  fillColorClass = 'bg-vs-green-200',
  hoverColorClass = 'hover:bg-vs-green-300',
  borderColorClass = 'border-vs-green-200',
  widthClass = 'w-[120px]',
  heightClass = 'h-[30px]',
  textColor = 'text-vs-green',
  font = 'text-13',
  onClick,
  submit,
  text,
  disabled,
  border = `border-solid border ${borderColorClass}`,
}) {
  let classList = `py-1 px-3 rounded-sm bg-clip-padding font-normal
            ${fillColorClass}
            ${hoverColorClass}
            ${widthClass}
            ${textColor}
            ${font}
            ${heightClass}
            ${border}`;
  return (
    <>
      {onClick && (
        <button
          className={classList}
          onClick={onClick}
          disabled={disabled ? disabled : false}
        >
          {text}
        </button>
      )}
      {submit && (
        <button
          className={classList}
          type="submit"
          disabled={disabled ? disabled : false}
        >
          {text}
        </button>
      )}
    </>
  );
}
