import React from 'react';
import Select from 'react-select';

export const SelectInput = ({
  type = 'text',
  options,
  selectValue,
  onSelectChange,
  inputValue,
  onInputChange,
  maxLength,
  index,
}) => {
  const checkIfNumber = (e) => {
    if (type === 'number') {
      const regex = new RegExp(
        /(^\d*$)|(Backspace|Tab|Delete|ArrowLeft|ArrowRight)/
      );
      return !e.key.match(regex) && e.preventDefault();
    }
  };

  return (
    <>
      <Select
        options={options}
        value={selectValue}
        onChange={(option) => onSelectChange(option, index)}
        className="w-full p-2.5"
        menuPosition="fixed"
      />
      <div className="p-2.5">
        <input
          type={type}
          placeholder="Filter..."
          value={inputValue == null ? '' : inputValue}
          onChange={(e) => onInputChange(e.target.value, index)}
          className="w-full py-1.5 pl-2.5 text-[13px] border-[#ccc] rounded"
          maxLength={maxLength}
          onKeyDown={checkIfNumber}
          onWheel={(e) => type === 'number' && e.target.blur()}
        />
      </div>
    </>
  );
};
