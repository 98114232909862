import { useEffect, useState } from 'react';

export default (props) => {
  const HIDDEN_STATE = 'hidden';
  const INDETERMINATE_STATE = 'indeterminate';
  const CHECKED_STATE = 'checked';
  const UNCHECKED_STATE = 'unchecked';
  const [checkboxState, setCheckboxState] = useState(HIDDEN_STATE);

  props.api.addEventListener('selectionChanged', () => {
    updateCheckboxState();
  });

  props.api.addEventListener('paginationChanged', (params) => {
    // new page is not always true when navigating though pages
    // so this handles only going to previous cached page
    if (params.newPage) {
      props.api.deselectAll();
    } else {
      updateCheckboxState();
    }
  });

  const updateCheckboxState = () => {
    if (props.api.destroyCalled) {
      return;
    }
    if (props.api.getDisplayedRowCount() === 0) {
      setCheckboxState(HIDDEN_STATE);
    } else {
      if (props.api.getSelectedNodes().length === 0) {
        setCheckboxState(UNCHECKED_STATE);
      } else if (allOnPageSelected()) {
        setCheckboxState(CHECKED_STATE);
      } else {
        setCheckboxState(INDETERMINATE_STATE);
      }
    }
  };

  const toggleSelectAll = () => {
    setCheckboxState(
      checkboxState === CHECKED_STATE ? UNCHECKED_STATE : CHECKED_STATE
    );
  };

  useEffect(() => {
    if (checkboxState === CHECKED_STATE) {
      getCurrentPageNodesIds().forEach((id) => {
        if (id) {
          props.api.getRowNode(id).setSelected(true);
        }
      });
    } else if (checkboxState === UNCHECKED_STATE) {
      props.api.deselectAll();
    }
  }, [checkboxState]);

  useEffect(() => {
    if (
      props.api.getDisplayedRowCount() > 0 &&
      props.api.getSelectedNodes().length === 0
    ) {
      setCheckboxState(UNCHECKED_STATE);
    } else if (props.api.getDisplayedRowCount() > 0 && allOnPageSelected()) {
      setCheckboxState(CHECKED_STATE);
    } else if (props.api.getDisplayedRowCount() > 0) {
      setCheckboxState(INDETERMINATE_STATE);
    } else {
      setCheckboxState(HIDDEN_STATE);
    }
  }, []);

  function getCurrentPageNodesIds() {
    let currentPage = props.api.paginationGetCurrentPage();
    let pageSize = props.api.paginationGetPageSize();
    if (props.api.getDisplayedRowCount() !== 0) {
      let startNodeIndex = pageSize * currentPage;
      let endNodeIndex = (currentPage + 1) * pageSize - 1;
      if (onLastPage()) {
        endNodeIndex = startNodeIndex + getLastPageNodesCount();
      }
      let ids = [];
      props.api.forEachNode((node) => {
        if (node.rowIndex <= endNodeIndex && node.rowIndex >= startNodeIndex) {
          ids.push(node.id);
        }
      });
      return ids;
    } else {
      return [];
    }
  }
  function onLastPage() {
    return (
      props.api.paginationGetTotalPages() ===
      props.api.paginationGetCurrentPage() + 1
    );
  }

  function allOnPageSelected() {
    if (onLastPage()) {
      return getLastPageNodesCount() === props.api.getSelectedNodes().length;
    } else {
      return (
        props.api.getSelectedNodes().length ===
        props.api.paginationGetPageSize()
      );
    }
  }

  function getLastPageNodesCount() {
    return props.api.getDisplayedRowCount() % props.api.paginationGetPageSize();
  }

  let checkBox = (
    <input
      type="checkbox"
      id="selectAllCheckbox"
      checked={checkboxState === CHECKED_STATE}
      ref={(input) => {
        if (input) {
          input.indeterminate = checkboxState === INDETERMINATE_STATE;
        }
      }}
      onChange={toggleSelectAll}
      className="border border-gray rounded-sm checked:bg-blue-600 focus:ring-0"
    />
  );
  return <div>{checkboxState !== HIDDEN_STATE && checkBox}</div>;
};
