import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCodeTags,
  getGroups,
  getPractices,
  getRevenueCategories,
} from '../../services/mappingDataServices';
import { getUsers } from '../../services/userServices';
import { getError } from '../../helpers/errorHelper';

export const loadCodeTags = createAsyncThunk(
  'table/getCodeTags',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getCodeTags()).data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const loadPractices = createAsyncThunk(
  'table/getPractices',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getPractices()).data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const loadGroups = createAsyncThunk(
  'table/getGroups',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getGroups()).data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const loadRevenueCategories = createAsyncThunk(
  'table/getRevenueCategories',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getRevenueCategories()).data;
    } catch (error) {
      return rejectWithValue(getError(error));
    }
  }
);

export const loadUsers = createAsyncThunk(
  'table/getUsers',
  async (arg, { rejectWithValue }) => {
    try {
      return (await getUsers()).data;
    } catch (error) {
      if (error.response.status === 403) {
        return [];
      }
      return rejectWithValue(getError(error));
    }
  }
);
