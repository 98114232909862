import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  loadPipelineStatuses,
  removePipelineStatus,
} from '../../../features/pipeline-status/pipelineStatusActions';
import Loader from '../../common/Loader';
import ErrorAlert from '../../common/ErrorAlert';
import ConfirmationModal from '../../common/ConfirmationModal';
import FormModal from '../../common/FormModal';
import EditPipelineStatusForm from './forms/EditPipelineStatusForm';
import ColoredButton from '../../common/ColoredButton';
import { getError } from '../../../helpers/errorHelper';
import { PIPELINE_STATUS_TABLE_COLUMNS } from '../../../constants/constants';

export default function PipelineStatuses() {
  const {
    loadingPipelineStatuses,
    loadingPipelineStatusesError,
    pipelineStatuses,
  } = useSelector((state) => state.pipelineStatus);
  const dispatch = useDispatch();
  const [newPipelineStatusOpen, setNewPipelineStatusOpen] = useState(false);
  const [editPipelineStatusInfo, setEditPipelineStatusInfo] = useState(null);
  const [deletePipelineStatusInfo, setDeletePipelineStatusInfo] =
    useState(null);
  const [alertError, setAlertError] = useState(null);

  useEffect(() => {
    if (!pipelineStatuses) {
      dispatch(loadPipelineStatuses());
    }
  }, []);

  const onStatusDelete = async () => {
    try {
      await dispatch(
        removePipelineStatus(deletePipelineStatusInfo.id)
      ).unwrap();
    } catch (error) {
      setAlertError(error);
    } finally {
      setDeletePipelineStatusInfo(null);
    }
  };

  return (
    <>
      {loadingPipelineStatuses && !loadingPipelineStatusesError && <Loader />}
      {!loadingPipelineStatuses &&
        !loadingPipelineStatusesError &&
        pipelineStatuses && (
          <div>
            <div className="flex justify-end">
              <ColoredButton
                padding="px-1"
                text="Add New"
                widthClass="w-[100px]"
                onClick={() => setNewPipelineStatusOpen(true)}
              />
            </div>
            <div className="table border-collapse table-auto w-full text-sm">
              <div className="table-header-group">
                <div className="table-row border-b-2 text-15 font-medium text-purple-vs-hover">
                  {PIPELINE_STATUS_TABLE_COLUMNS.map((column) => (
                    <div key={column} className="table-cell p-2">
                      {column}
                    </div>
                  ))}
                </div>
              </div>
              <div className="table-row-group text-[13px] font-light">
                {pipelineStatuses.map((status) => (
                  <div
                    role="row"
                    key={status.id}
                    className="table-row border-b-2 h-12"
                  >
                    <div className="config-table-cell">{status.id}</div>
                    <div className="config-table-cell">
                      {status.status_name}
                    </div>
                    <div className="config-table-cell">
                      {moment(status.created_at).format('YYYY-MM-DD')}
                    </div>
                    <div className="config-table-cell text-right">
                      <div
                        className="inline-block cursor-pointer"
                        onClick={() => {
                          setEditPipelineStatusInfo(status);
                        }}
                      >
                        Edit
                      </div>
                      <div
                        className="inline-block ml-5 text-warning-red cursor-pointer"
                        onClick={() => {
                          setDeletePipelineStatusInfo(status);
                        }}
                      >
                        Delete
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      <FormModal
        openState={newPipelineStatusOpen}
        onClose={() => setNewPipelineStatusOpen(false)}
        title="New Pipeline Status"
        form={
          <EditPipelineStatusForm
            pipelineStatus={{}}
            pipelineStatuses={pipelineStatuses}
            onFinish={() => setNewPipelineStatusOpen(false)}
          />
        }
      />
      <FormModal
        openState={!!editPipelineStatusInfo}
        onClose={() => setEditPipelineStatusInfo(null)}
        title="Edit Pipeline Status"
        form={
          <EditPipelineStatusForm
            pipelineStatus={editPipelineStatusInfo}
            pipelineStatuses={pipelineStatuses}
            onFinish={() => setEditPipelineStatusInfo(null)}
          />
        }
      />
      {deletePipelineStatusInfo && (
        <ConfirmationModal
          title="Delete Pipeline Status"
          text={`Are you sure you want to delete ${deletePipelineStatusInfo.status_name}? This will remove this status from all codes in the database. This action cannot be undone.`}
          buttonText="Yes, I’m sure"
          operationType="danger"
          onButtonClick={onStatusDelete}
          onClose={() => setDeletePipelineStatusInfo(null)}
          isOpen={!!deletePipelineStatusInfo}
        />
      )}
      {alertError && (
        <ErrorAlert
          isOpen={true}
          error={getError(alertError)}
          buttonText="Ok"
          onButtonClick={() => setAlertError(null)}
          onClose={() => setAlertError(null)}
          closingButton={false}
        />
      )}
      {loadingPipelineStatusesError && (
        <ErrorAlert
          isOpen={true}
          error={getError(loadingPipelineStatusesError)}
          buttonText="Try reloading"
          onButtonClick={() => window.location.reload()}
          onClose={() => setAlertError(null)}
          closingButton={true}
        />
      )}
    </>
  );
}
