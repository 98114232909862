import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getError } from '../../../../helpers/errorHelper';
import ColoredButton from '../../../common/ColoredButton';
import OutlineButton from '../../../common/OutlineButton';
import {
  addPipelineStatus,
  editPipelineStatus,
} from '../../../../features/pipeline-status/pipelineStatusActions';

export default function EditPipelineStatusForm({
  pipelineStatus,
  pipelineStatuses,
  onFinish,
}) {
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitForm = async (data) => {
    if (pipelineStatus.id) {
      try {
        await dispatch(
          editPipelineStatus({ id: pipelineStatus.id, data: data })
        ).unwrap();
        onFinish();
      } catch (error) {
        setServerError(getError(error).description);
      }
    } else {
      try {
        await dispatch(addPipelineStatus(data)).unwrap();
        onFinish();
      } catch (error) {
        setServerError(getError(error).description);
      }
    }
  };

  const validateUniqueStatusName = (statusName) => {
    return (
      !pipelineStatuses.some(
        (status) =>
          status.status_name === statusName && status.id !== pipelineStatus.id
      ) || 'Status Name is not unique'
    );
  };

  return (
    <>
      <form className="mt-6" onSubmit={handleSubmit(submitForm)}>
        <div className="relative mb-8">
          <input
            id="statusName"
            type="text"
            className="formInput peer"
            defaultValue={pipelineStatus.status_name}
            {...register('status_name', {
              required: 'Status Name is required',
              validate: (statusName) => validateUniqueStatusName(statusName),
            })}
          />
          <label htmlFor="statusName" className="formLabel">
            Status Name
          </label>
          {errors.status_name && (
            <p className="formError">{errors.status_name.message}</p>
          )}
          <p className="formError text-center w-[288px]">{serverError}</p>
        </div>
        <div className="text-center">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onFinish}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
          />
        </div>
      </form>
    </>
  );
}
