import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';
import { Outlet, useNavigate } from 'react-router';
import React, { useEffect } from 'react';
import { tokenExpired } from '../utils/helper';
import {
  loadUserDetails,
  logout,
  refreshTokens,
} from '../features/user/userActions';
import * as Sentry from '@sentry/browser';
import { logger } from '../services/logger';

const ProtectedRoute = ({ roles }) => {
  const { token, userInfo, tokenExpiry, refreshTokenExpiry } = useSelector(
    (state) => state.user
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!token) {
      navigate('/login');
    } else if (tokenExpired(tokenExpiry)) {
      if (tokenExpired(refreshTokenExpiry) || !refreshTokenExpiry) {
        dispatch(logout());
      } else {
        logger.logEvent(
          `Protected Redirect: Refreshing token not expired so dispatching refresh: ${refreshTokenExpiry}`
        );
        dispatch(refreshTokens());
      }
    } else if (!userInfo) {
      dispatch(loadUserDetails());
    }
  }, [navigate, userInfo, token, tokenExpiry]);

  useEffect(() => {
    if (userInfo) {
      Sentry.setUser({ email: userInfo.email });
    }
  }, [userInfo]);

  if (!token || !userInfo) {
    return null;
  }

  if (!Array.isArray(roles) || !roles.includes(userInfo.role)) {
    return <div className="h-full w-full flex flex-col">Forbidden</div>;
  }

  return (
    <div className="h-full w-full flex flex-col">
      <Navbar />
      <Outlet />
    </div>
  );
};

export default ProtectedRoute;
