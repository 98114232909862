import React, { useState } from 'react';
import ColoredButton from '../../common/ColoredButton';
import { createUser, updateUser } from '../../../services/userServices';
import Select from 'react-select';
import { getRolesSelectElements } from '../../../helpers/selectHelper';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import { getError } from '../../../helpers/errorHelper';
import {
  EMAIL_PATTERN,
  MAX_DISPLAY_NAME_LENGTH,
  MIN_PASSWORD_LENGTH,
} from '../../../constants/constants';

export default function EditUserForm({ user, onCancel, onUserUpdate }) {
  const [serverError, setServerError] = useState(null);
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitForm = (data) => {
    data.role = data.role.value;
    if (data.password === '' || data.password === null) {
      delete data.password;
    }

    if (user.id) {
      updateUser(user.id, data)
        .then(({ data }) => {
          onUserUpdate(data);
          onCancel();
        })
        .catch((error) => {
          setServerError(getError(error).description);
        });
    } else {
      createUser(data)
        .then(({ data }) => {
          onUserUpdate(data);
          onCancel();
        })
        .catch((error) => {
          setServerError(getError(error).description);
        });
    }
  };
  return (
    <>
      <form className="mt-6" onSubmit={handleSubmit(submitForm)}>
        <div className="relative">
          <input
            id="firstName"
            type="text"
            className="formInput peer"
            defaultValue={user.first_name}
            {...register('first_name', { required: true })}
          />
          <label htmlFor="firstName" className="formLabel">
            First Name
          </label>
          {errors.first_name?.type === 'required' && (
            <p className="formError">First Name is required</p>
          )}
        </div>
        <div className="relative mt-3 mb-8">
          <input
            id="lastName"
            type="text"
            className="formInput peer"
            defaultValue={user.last_name}
            {...register('last_name', { required: true })}
          />
          <label htmlFor="lastName" className="formLabel">
            Last Name
          </label>
          {errors.last_name?.type === 'required' && (
            <p className="formError">Last Name is required</p>
          )}
        </div>
        <div className="relative mt-3 mb-8">
          <input
            id="displayName"
            type="text"
            className="formInput peer"
            defaultValue={user.display_name}
            {...register('display_name', {
              maxLength: MAX_DISPLAY_NAME_LENGTH,
            })}
          />
          <label htmlFor="displayName" className="formLabel">
            Display Name
          </label>
          {errors.display_name?.type === 'required' && (
            <p className="formError">Display Name is required</p>
          )}
          {errors.display_name?.type === 'maxLength' && (
            <p className="formError">
              Display Name can have up to {MAX_DISPLAY_NAME_LENGTH} characters
            </p>
          )}
        </div>
        <div className="relative mt-3 mb-8">
          <input
            id="email"
            type="text"
            className="formInput peer"
            defaultValue={user.email}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: EMAIL_PATTERN,
                message: 'Entered value does not match email format',
              },
            })}
          />
          <label htmlFor="email" className="formLabel">
            Email
          </label>
          {errors.email?.message && (
            <p className="formError">{errors.email.message}</p>
          )}
        </div>
        <div className="relative mt-3 mb-8">
          <input
            id="password"
            type="password"
            className="formInput peer"
            placeholder="********"
            {...register('password', {
              minLength: MIN_PASSWORD_LENGTH,
            })}
          />
          <label htmlFor="password" className="formLabel">
            Password
          </label>
          {errors.password?.type === 'minLength' && (
            <p className="formError">
              Password must have at least 8 characters
            </p>
          )}
        </div>
        <div className="relative mt-3 mb-8">
          <input
            id="password_confirmation"
            className="formInput peer"
            type="password"
            placeholder="*********"
            {...register('password_confirmation', {
              minLength: MIN_PASSWORD_LENGTH,
              validate: (val) => {
                if (watch('password') !== val) {
                  return 'Passwords do no match';
                }
              },
            })}
          />
          <label htmlFor="password_confirmation" className="formLabel">
            Confirm Password
          </label>
          {errors.password_confirmation?.type === 'minLength' && (
            <p className="formError">
              Password must have at least 8 characters
            </p>
          )}
          {errors.password_confirmation?.type === 'validate' && (
            <p className="formError">{errors.password_confirmation.message}</p>
          )}
        </div>
        <div className="relative mt-3 mb-14">
          <Controller
            name="role"
            id="role"
            control={control}
            rules={{ required: 'Role is required' }}
            defaultValue={getRolesSelectElements().find((op) => {
              return op.value === user.role;
            })}
            render={({ field }) => (
              <Select
                {...field}
                id="role"
                inputId="role-input"
                className="formInput select"
                options={getRolesSelectElements()}
              />
            )}
          />
          <label htmlFor="role-input" className="formLabel">
            Role
          </label>
          {errors.role?.type === 'required' && (
            <p className="formError">{errors.role.message}</p>
          )}
          <p className="formError text-center mt-6 w-[288px]">{serverError}</p>
        </div>
        <div className="text-center">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onCancel}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
          />
        </div>
      </form>
    </>
  );
}
