import moment from 'moment/moment';
import {
  CLAIM_JOB_ACTION,
  defaultJobsStatusFilter,
  COMPLETE_JOB_ACTION,
  COMPLETE_JOB_ACTION_LABEL,
  JOB_STATUS_AVAILABLE,
  JOB_STATUSES,
  jobStatusFiltersSelect,
  jobStatusHistoryFiltersSelect,
  jobTypeFiltersSelect,
  jobTypes,
  PRIORITIES,
  PRIORITY_ORDER,
  priorityFiltersSelect,
  RATE_TYPES_DISPLAY,
  JOB_STATUS_FAILED,
  JOB_STATUS_COMPLETED,
  DOWNLOAD_CSV_ACTION,
  RATE_TYPE_PER_HOUR,
} from '../../constants/jobConstants';
import { useSelector } from 'react-redux';
import ColoredButton from '../common/ColoredButton';
import LinkButton from '../common/LinkButton';
import PriorityLabel from '../common/PriorityLabel';
import { sortVaccineProtocolColumn } from '../../helpers/jobsTableHelper';
import { JobActionsDropdown } from './JobActionsDropdown';
import {
  canMarkJobCompleted,
  jobActiveBelongsToUser,
  jobMappingsAllowedForUser,
} from '../../helpers/jobsHelper';
import checked from '../../assets/icons/checked-green.svg';
import { isAdmin } from '../../helpers/userHelper';
import TableSetFilter from '../table/filters/TableSetFilter';
import TableDateFilter from '../table/filters/TableDateFilter';
import TableCheckboxFilter from '../table/filters/TableCheckboxFilter';
import React, { memo } from 'react';
import TableRadioFilter from '../table/filters/TableRadioFilter';
import { JobStatusCellIcon } from './JobStatusCellIcon';
import infoIcon from '../../assets/icons/info.svg';
import invoicedIcon from '../../assets/icons/invoiced_icon.svg';
import downloadCSVIcon from '../../assets/icons/download_csv.svg';
import hiddenIcon from '../../assets/icons/hidden.svg';
import TableTextFilter from '../table/filters/TableTextFilter';
import { defaultTextFilterOperators } from '../../constants/constants';

export const getJobsColumns = (
  { setJobAction },
  { setAlertError },
  { location },
  { usersWithoutAutomapper }
) => {
  const { userInfo } = useSelector((state) => state.user);

  const priorityCellRenderer = (params) => {
    let cellStyle = PRIORITIES.find((style) => style.name === params.value);
    return (
      <div className="w-full p-1">
        <PriorityLabel
          text={params.value}
          bgColor={cellStyle.bgColor}
          textColor={cellStyle.textColor}
        />
      </div>
    );
  };

  const priorityComparator = (a, b) => {
    return PRIORITY_ORDER[b] - PRIORITY_ORDER[a];
  };

  const jobIdCellRenderer = (params) => {
    return jobMappingsAllowedForUser(params.data, userInfo) ? (
      <LinkButton
        text={`#${params.value}`}
        fontWeight="font-medium"
        fontSize="text-[13px]"
        textColor="text-purple-vs"
        hoverTextColor="hover:text-purple-hover"
        navigation={`/jobmapping/${params.value}`}
      />
    ) : (
      <>#{params.value}</>
    );
  };

  const vaccineProtocolComparator = (a, b, protocol, isInverted) => {
    if (a === null && b === null) {
      return 0;
    }
    if (a === null) {
      return isInverted ? -1 : 1;
    }
    if (b === null) {
      return isInverted ? 1 : -1;
    }
    return sortVaccineProtocolColumn(a[protocol], b[protocol], isInverted);
  };

  const rateCellFormatter = (params) => {
    return params.value
      ? `$${params.value}${RATE_TYPES_DISPLAY[params.data.rate_type]}`
      : '\u2014';
  };

  const mappedAndTimeSpentFormatter = (params) => {
    let mapped_time = params.data.mapped_time
      ? `${Number.parseFloat(params.data.mapped_time / 60).toFixed(2)} h`
      : '0.00 h';

    return params.data.rate_type === RATE_TYPE_PER_HOUR
      ? mapped_time
      : params.value;
  };

  const statusCellRenderer = (params) => {
    let status = JOB_STATUSES[params.value];
    return (
      <div className="inline-flex">
        <span className="align-middle">{status}</span>
        {params.value === JOB_STATUS_FAILED && params.data.error_message && (
          <JobStatusCellIcon
            testId="failedIcon"
            offset={[48, 10]}
            iconSrc={infoIcon}
            displayMessage={params.data.error_message}
          />
        )}
        {params.data.hidden_from_mapper && (
          <JobStatusCellIcon
            testId="hiddenIcon"
            offset={[48, 10]}
            iconSrc={hiddenIcon}
            displayMessage="Hidden from Mapper"
          />
        )}
        {params.data.invoiced && (
          <JobStatusCellIcon
            testId="invoicedIcon"
            offset={[23, 10]}
            iconSrc={invoicedIcon}
            displayMessage="Invoiced"
          />
        )}
        {params.value === JOB_STATUS_COMPLETED && (
          <JobStatusCellIcon
            testId="downloadCSVIcon"
            additionalClasses="cursor-pointer"
            offset={[39, 10]}
            iconSrc={downloadCSVIcon}
            displayMessage="Download CSV"
            jobId={params.data.id}
            actionHandler={() =>
              setJobAction({
                action: DOWNLOAD_CSV_ACTION,
                jobId: params.data.id,
              })
            }
          />
        )}
      </div>
    );
  };

  const statusComparator = (a, b) => {
    return JOB_STATUSES[a].localeCompare(JOB_STATUSES[b]);
  };

  const actionCellRenderer = (params) => {
    return (
      <>
        {params.data.status === JOB_STATUS_AVAILABLE &&
          moment.utc(Date.now()).format('YYYY-MM-DD') >=
            params.data.available_at && (
            <ColoredButton
              padding="p-[11px]"
              additionalClasses="float-left flex justify-center items-center"
              text="Claim"
              font="text-xs"
              widthClass="w-[70px]"
              heightClass="h-[30px]"
              onClick={() =>
                setJobAction({
                  action: CLAIM_JOB_ACTION,
                  jobId: params.data.id,
                })
              }
            />
          )}
        {canMarkJobCompleted(params.data, userInfo) && (
          <ColoredButton
            padding="p-[11px]"
            additionalClasses="float-left flex justify-center items-center"
            text={COMPLETE_JOB_ACTION_LABEL}
            font="text-xs"
            widthClass="w-[70px]"
            heightClass="h-[30px]"
            fillColorClass="bg-blue"
            hoverColorClass="hover:bg-blue-hover"
            border="border border-solid border-blue outline-none"
            onClick={() =>
              setJobAction({
                action: COMPLETE_JOB_ACTION,
                jobId: params.data.id,
              })
            }
          />
        )}
        {jobActiveBelongsToUser(params.data, userInfo.id) &&
          !canMarkJobCompleted(params.data, userInfo) && (
            <div className="float-left text-vs-green w-[70px]">
              <img src={checked} alt="Claimed" className="p-2 inline" />
              Claimed
            </div>
          )}
        <JobActionsDropdown
          setJobAction={setJobAction}
          setAlertError={setAlertError}
          mappingJobDetails={params.data}
          userInfo={userInfo}
        />
      </>
    );
  };

  let jobsColumnDefinitions = [
    {
      field: 'invoiced',
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableRadioFilter,
    },
    {
      field: 'created_at',
      valueGetter: (params) => params.data.created_at.slice(0, 10),
      headerName: 'Date Created',
      headerTooltip: 'Date Created',
      minWidth: 75,
      maxWidth: 90,
      hide: true,
      filter: TableDateFilter,
    },
    {
      field: 'available_at',
      headerName: 'Date Available',
      headerTooltip: 'Date Available',
      minWidth: 75,
      maxWidth: 100,
      filter: TableDateFilter,
    },
    {
      field: 'id',
      headerName: 'Job id',
      headerTooltip: 'Job id',
      minWidth: 45,
      maxWidth: 70,
      cellRenderer: memo(jobIdCellRenderer),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      headerTooltip: 'Priority',
      minWidth: 72,
      maxWidth: 72,
      comparator: priorityComparator,
      cellRenderer: memo(priorityCellRenderer),
      filter: TableSetFilter,
      filterParams: {
        values: priorityFiltersSelect,
      },
    },
    {
      field: 'job_type',
      headerName: 'Job Type',
      headerTooltip: 'Job Type',
      minWidth: 60,
      maxWidth: 85,
      valueFormatter: (params) => jobTypes[params.value],
      filter: TableCheckboxFilter,
      filterParams: {
        values: jobTypeFiltersSelect,
      },
    },
    {
      field: 'practice_id',
      headerName: 'Clinic Number',
      headerTooltip: 'Clinic Number',
      minWidth: 60,
      maxWidth: 100,
    },
    {
      field: 'clinic_name',
      headerName: 'Clinic Name',
      headerTooltip: 'Clinic Name',
      ...(!isAdmin(userInfo) && { hide: true }),
      ...(!isAdmin(userInfo) && { suppressColumnsToolPanel: true }),
      minWidth: 120,
    },
    {
      field: 'project_tracking',
      headerName: 'Project Tracking',
      headerTooltip: 'Project Tracking',
      minWidth: 90,
      maxWidth: 150,
      hide: true,
      filter: TableTextFilter,
      filterParams: {
        operators: defaultTextFilterOperators,
      },
    },
    {
      field: 'admin_description',
      headerName: 'Job description',
      headerTooltip: 'Job description',
      flex: 2,
      minWidth: 140,
      cellStyle: { whiteSpace: 'pre-wrap' },
      autoHeight: true,
    },
    {
      field: 'vaccine_protocol',
      headerName: 'DHPP',
      valueFormatter: (params) =>
        params.value && params.value.dhpp ? params.value.dhpp : '\u2014',
      valueParser: (params) => params.value,
      comparator: (a, b, nodeA, nodeB, isInverted) =>
        vaccineProtocolComparator(a, b, 'dhpp', isInverted),
      minWidth: 45,
      maxWidth: 55,
      hide: true,
    },
    {
      field: 'vaccine_protocol',
      headerName: 'FVRCP',
      valueFormatter: (params) =>
        params.value && params.value.fvrcp ? params.value.fvrcp : '\u2014',
      valueParser: (params) => params.value,
      comparator: (a, b, nodeA, nodeB, isInverted) =>
        vaccineProtocolComparator(a, b, 'fvrcp', isInverted),
      minWidth: 45,
      maxWidth: 55,
      hide: true,
    },
    {
      field: 'vaccine_protocol',
      headerName: 'Rabies',
      valueFormatter: (params) =>
        params.value && params.value.rabies ? params.value.rabies : '\u2014',
      valueParser: (params) => params.value,
      comparator: (a, b, nodeA, nodeB, isInverted) =>
        vaccineProtocolComparator(a, b, 'rabies', isInverted),
      minWidth: 45,
      maxWidth: 55,
      hide: true,
    },
    {
      field: 'unverified_code_count',
      headerName: 'Code Count',
      headerTooltip: 'Code Count',
      minWidth: 50,
      maxWidth: 70,
    },
    {
      field: 'mapped_code_count',
      headerName: 'Mapped/Hours',
      headerTooltip: 'Mapped/Hours',
      minWidth: 70,
      valueFormatter: mappedAndTimeSpentFormatter,
    },
    {
      field: 'rate',
      headerName: 'Rate ($)',
      headerTooltip: 'Rate ($)',
      minWidth: 75,
      maxWidth: 90,
      valueFormatter: rateCellFormatter,
    },
    {
      field: 'admin_notes',
      headerName: 'Admin Notes',
      headerTooltip: 'Admin Notes',
      flex: 2,
      minWidth: 140,
      hide: !isAdmin(userInfo),
      suppressColumnsToolPanel: !isAdmin(userInfo),
      cellStyle: { whiteSpace: 'pre-wrap' },
      autoHeight: true,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      headerTooltip: 'Notes',
      flex: 2,
      minWidth: 140,
      cellStyle: { whiteSpace: 'pre-wrap' },
      autoHeight: true,
    },
    {
      field: 'claimed_at',
      headerName: 'Date Claimed',
      headerTooltip: 'Date Claimed',
      minWidth: 75,
      maxWidth: 90,
      valueGetter: (params) =>
        params.data.claimed_at
          ? moment(params.data.claimed_at).format('YYYY-MM-DD')
          : null,
      filter: TableDateFilter,
    },
    {
      field: 'claimed_by_formatted',
      headerName: 'Claimed By',
      headerTooltip: 'Claimed By',
      minWidth: 60,
      maxWidth: 100,
      hide: !isAdmin(userInfo),
      suppressColumnsToolPanel: !isAdmin(userInfo),
      ...(isAdmin(userInfo) && { filter: TableSetFilter }),
      filterParams: {
        values: usersWithoutAutomapper,
      },
    },
    {
      field: 'completed_at',
      headerName: 'Date completed',
      headerTooltip: 'Date completed',
      minWidth: 75,
      maxWidth: 100,
      hide: true,
      filter: TableDateFilter,
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: memo(statusCellRenderer),
      comparator: statusComparator,
      headerTooltip: 'Status',
      minWidth: 130,
      autoHeight: true,
      getQuickFilterText: (params) => {
        return JOB_STATUSES[params.value];
      },
      filter: TableSetFilter,
      filterParams: {
        values: jobStatusFiltersSelect,
        defaultValue: defaultJobsStatusFilter,
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerTooltip: 'Action',
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      cellStyle: {
        borderStyle: 'solid',
        borderColor: 'rgb(223, 223, 223)',
        borderWidth: '0 1px 0 1px',
      },
      cellRenderer: memo(actionCellRenderer),
      autoHeight: true,
    },
  ];

  let jobsHistoryColumnDefinitions = [
    {
      valueGetter: (params) => params.data.created_at.slice(0, 10),
      headerName: 'Date Created',
      headerTooltip: 'Date Created',
      minWidth: 85,
      maxWidth: 90,
      hide: true,
    },
    {
      field: 'invoiced',
      hide: true,
      suppressColumnsToolPanel: true,
      filter: TableRadioFilter,
    },
    {
      field: 'available_at',
      headerName: 'Date Available',
      headerTooltip: 'Date Available',
      minWidth: 85,
      maxWidth: 120,
      hide: true,
    },
    {
      field: 'completed_at',
      headerName: 'Date completed',
      headerTooltip: 'Date completed',
      minWidth: 75,
      filter: TableDateFilter,
    },
    {
      field: 'id',
      headerName: 'Job id',
      headerTooltip: 'Job id',
      minWidth: 45,
      cellRenderer: memo(jobIdCellRenderer),
    },
    {
      field: 'priority',
      headerName: 'Priority',
      headerTooltip: 'Priority',
      minWidth: 72,
      maxWidth: 72,
      comparator: priorityComparator,
      cellRenderer: memo(priorityCellRenderer),
      hide: true,
      filter: TableSetFilter,
      filterParams: {
        values: priorityFiltersSelect,
      },
    },
    {
      field: 'job_type',
      headerName: 'Job Type',
      headerTooltip: 'Job Type',
      minWidth: 60,
      valueFormatter: (params) => jobTypes[params.value],
      filter: TableCheckboxFilter,
      filterParams: {
        values: jobTypeFiltersSelect,
      },
    },
    {
      field: 'practice_id',
      headerName: 'Clinic Number',
      headerTooltip: 'Clinic Number',
      minWidth: 60,
      maxWidth: 100,
      hide: true,
    },
    {
      field: 'project_tracking',
      headerName: 'Project Tracking',
      headerTooltip: 'Project Tracking',
      minWidth: 120,
      filter: TableTextFilter,
      filterParams: {
        operators: defaultTextFilterOperators,
      },
    },
    {
      field: 'admin_description',
      headerName: 'Job description',
      headerTooltip: 'Job description',
      flex: 2,
      minWidth: 140,
      hide: true,
      autoHeight: true,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      headerTooltip: 'Notes',
      flex: 2,
      minWidth: 140,
      hide: true,
      cellStyle: { whiteSpace: 'pre-wrap' },
      autoHeight: true,
    },
    {
      field: 'vaccine_protocol',
      headerName: 'DHPP',
      maxWidth: 90,
      valueFormatter: (params) =>
        params.value && params.value.dhpp ? params.value.dhpp : '\u2014',
      valueParser: (params) => params.value,
      comparator: (a, b, nodeA, nodeB, isInverted) =>
        vaccineProtocolComparator(a, b, 'dhpp', isInverted),
      hide: true,
    },
    {
      field: 'vaccine_protocol',
      headerName: 'FVRCP',
      maxWidth: 90,
      valueFormatter: (params) =>
        params.value && params.value.fvrcp ? params.value.fvrcp : '\u2014',
      valueParser: (params) => params.value,
      comparator: (a, b, nodeA, nodeB, isInverted) =>
        vaccineProtocolComparator(a, b, 'fvrcp', isInverted),
      hide: true,
    },
    {
      field: 'vaccine_protocol',
      headerName: 'Rabies',
      maxWidth: 90,
      valueFormatter: (params) =>
        params.value && params.value.rabies ? params.value.rabies : '\u2014',
      valueParser: (params) => params.value,
      comparator: (a, b, nodeA, nodeB, isInverted) =>
        vaccineProtocolComparator(a, b, 'rabies', isInverted),
      hide: true,
    },
    {
      field: 'unverified_code_count',
      headerName: 'Code Count',
      headerTooltip: 'Code Count',
      minWidth: 50,
    },
    {
      field: 'mapped_code_count',
      headerName: 'Mapped/Hours',
      headerTooltip: 'Mapped/Hours',
      minWidth: 70,
      valueFormatter: mappedAndTimeSpentFormatter,
    },
    {
      field: 'rate',
      headerName: 'Rate ($)',
      headerTooltip: 'Rate ($)',
      minWidth: 75,
      valueFormatter: rateCellFormatter,
    },
    {
      field: 'claimed_at',
      headerName: 'Date Claimed',
      headerTooltip: 'Date Claimed',
      minWidth: 75,
      maxWidth: 90,
      valueGetter: (params) =>
        params.data.claimed_at
          ? moment(params.data.claimed_at).format('YYYY-MM-DD')
          : null,
      hide: true,
      filter: TableDateFilter,
    },
    {
      field: 'status',
      headerName: 'Status',
      cellRenderer: memo(statusCellRenderer),
      comparator: statusComparator,
      headerTooltip: 'Status',
      minWidth: 120,
      maxWidth: 120,
      getQuickFilterText: (params) => {
        return JOB_STATUSES[params.value];
      },
      autoHeight: true,
      filter: TableSetFilter,
      filterParams: {
        values: jobStatusHistoryFiltersSelect,
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      headerTooltip: 'Action',
      minWidth: 130,
      maxWidth: 130,
      sortable: false,
      cellStyle: {
        borderStyle: 'solid',
        borderColor: 'rgb(223, 223, 223)',
        borderWidth: '0 1px 0 1px',
      },
      cellRenderer: memo(actionCellRenderer),
      autoHeight: true,
    },
  ];

  function findAppropriateColumnDefinitions() {
    switch (location.pathname) {
      case '/jobs':
        return jobsColumnDefinitions;
      case '/history':
        return jobsHistoryColumnDefinitions;
      default:
        return null;
    }
  }

  return findAppropriateColumnDefinitions();
};
