import { Dialog } from '@headlessui/react';
import ColoredButton from './ColoredButton';
import OutlineButton from './OutlineButton';
import alertIcon from '../../assets/icons/alert.svg';

export default function ErrorAlert({
  isOpen,
  onClose,
  error,
  onButtonClick,
  buttonText,
  closingButton,
  ctaButton = false,
  showAlertIcon = true,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex h-1/2 items-center justify-center p-8 text-center">
          <Dialog.Panel className="w-440 transform overflow-hidden rounded-2xl bg-white py-8 px-8 text-left align-middle shadow-xl transition-all">
            {showAlertIcon && (
              <img className="mx-auto mb-5" src={alertIcon} alt="alert-icon" />
            )}
            <Dialog.Title
              as="h3"
              className="text-center text-lg font-medium leading-6 font-bold text mb-7"
            >
              {error['title'] || 'Error happened'}
            </Dialog.Title>
            <Dialog.Description className="text-center text-gray-400">
              {error['description'] || 'Something went wrong.'}
            </Dialog.Description>
            <div className="text-center mt-8">
              {closingButton && (
                <OutlineButton
                  text="Cancel"
                  heightClass="h-10"
                  onClick={onClose}
                />
              )}
              {ctaButton ? (
                <ColoredButton
                  text={buttonText ? buttonText : 'Ok, got it!'}
                  heightClass="h-10"
                  onClick={onButtonClick}
                />
              ) : (
                <ColoredButton
                  fillColorClass="bg-red"
                  hoverColorClass="hover:bg-red-default-hover"
                  text={buttonText ? buttonText : 'Ok, got it!'}
                  widthClass={`w-[${buttonText ? 120 : 100}px]`}
                  heightClass="h-10"
                  border="border border-solid border-red outline-none"
                  onClick={onButtonClick}
                />
              )}
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
