import { Fragment, memo } from 'react';
import { Menu, Transition } from '@headlessui/react';

const MappingDropdown = memo(function MappingDropdown({
  items,
  title = 'More Actions',
}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex w-full items-center gap-x-1.5 text-14 font-medium text-purple-vs-hover">
        {({ open }) => (
          <>
            <div>{title}</div>
            <div className={`arrow ${open ? 'up' : 'down'} border-purple-vs`} />
          </>
        )}
      </Menu.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute z-10 mt-2 w-56 rounded bg-white border-[1px] divide-y divide-gray-100">
          {items.map((item) => (
            <div
              key={item.label}
              className={`py-1 ${
                item.disabled ? 'pointer-events-none' : 'cursor-pointer'
              }`}
            >
              <Menu.Item>
                {({ active }) => (
                  <span
                    className={`block px-4 py-2 text-13 ${
                      active && 'bg-neutral-100 text-gray-900 rounded'
                    } ${item.disabled ? 'text-gray-350' : 'text'}
                    ${
                      item.critical
                        ? 'hover:bg-red-200 hover:text-red text-red'
                        : 'text'
                    }
                    ${item.classes}`}
                    onClick={item.action}
                  >
                    {item.label}
                  </span>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
});

export default MappingDropdown;
