import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
  loadCodeTags,
  loadGroups,
  loadPractices,
  loadRevenueCategories,
  loadUsers,
} from '../../features/table/tableActions';
import Loader from '../common/Loader';
import ErrorAlert from '../common/ErrorAlert';
import JobMappingTable from '../table/JobMappingTable';
import { isAdmin } from '../../helpers/userHelper';
import JobActionPanel from './JobActionPanel';
import { loadPipelineStatuses } from '../../features/pipeline-status/pipelineStatusActions';
import { loadUserTableConfigurations } from '../../features/user-configuration/userConfigActions';

export default function JobTableMappings({ jobDetails }) {
  const [isLoaderVisible, setLoaderVisible] = useState(true);
  const [errorModalClosed, closeErrorModal] = useState(false);
  const {
    allCodeTags,
    allRevenueCategories,
    groups,
    practiceGroupRelationships,
    practices,
    preLoadingError,
    users,
  } = useSelector((state) => state.tableData);
  const { userInfo } = useSelector((state) => state.user);
  const { userTableConfigurations, error: userConfigError } = useSelector(
    (state) => state.userConfig
  );
  const { pipelineStatuses, loadingPipelineStatusesError } = useSelector(
    (state) => state.pipelineStatus
  );
  const dispatch = useDispatch();

  // we needed to make sure we have code tags and revenue categories loaded before ag grid is rendered, because of
  // fill operation grid option (which is dependant on code tags and categories) and stale fn closures
  // from their doc:
  // Note the if using Grid Options, the grid will not react to property changes.
  // For example gridOptions.rowData will only get used once when the grid is initialised, not if you change
  // gridOptions.rowData after the grid is initialised.
  // For this reason, while using React, it's best only use Grid Options for properties that do not change.

  useEffect(() => {
    if (!allCodeTags) {
      dispatch(loadCodeTags());
    }
  }, [allCodeTags]);

  useEffect(() => {
    if (!allRevenueCategories) {
      dispatch(loadRevenueCategories());
    }
  }, [allRevenueCategories]);

  useEffect(() => {
    if (!practices) {
      dispatch(loadPractices());
    }
  }, [practices]);

  useEffect(() => {
    if (!groups) {
      dispatch(loadGroups());
    }
  }, [groups]);

  useEffect(() => {
    if (isAdmin(userInfo) && !users) {
      dispatch(loadUsers());
    }
  }, [users]);

  useEffect(() => {
    if (!pipelineStatuses) dispatch(loadPipelineStatuses());
  }, [pipelineStatuses]);

  useEffect(() => {
    if (!userTableConfigurations) dispatch(loadUserTableConfigurations());
  }, [userTableConfigurations]);

  useEffect(() => {
    if (
      allCodeTags &&
      allRevenueCategories &&
      practices &&
      pipelineStatuses &&
      groups &&
      practiceGroupRelationships &&
      userTableConfigurations
    ) {
      setLoaderVisible(false);
    }
  }, [
    allRevenueCategories,
    allCodeTags,
    practices,
    pipelineStatuses,
    groups,
    practiceGroupRelationships,
    userTableConfigurations,
  ]);

  return (
    <>
      {isLoaderVisible && !preLoadingError && <Loader />}
      {!isLoaderVisible && !preLoadingError && (
        <>
          <JobActionPanel jobDetails={jobDetails} />
          <div id="mappings" className="flex flex-col h-full flex-grow-1">
            <div
              className="flex mx-4 mb-4 flex-grow relative overflow-hidden"
              id="tableContainer"
            >
              <div id="jobMappingTable" className="w-full h-full">
                <JobMappingTable jobDetails={jobDetails} />
              </div>
            </div>
          </div>
        </>
      )}
      {(preLoadingError || loadingPipelineStatusesError || userConfigError) &&
        !errorModalClosed && (
          <ErrorAlert
            isOpen={!errorModalClosed}
            error={
              preLoadingError || loadingPipelineStatusesError || userConfigError
            }
            buttonText="Try reloading"
            onButtonClick={() => window.location.reload()}
            onClose={() => closeErrorModal(true)}
            closingButton={true}
          />
        )}
    </>
  );
}
