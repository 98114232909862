import React from 'react';

export const ResetFilterButton = ({ onReset }) => {
  return (
    <div className="mr-2.5 text-right outline-none" tabIndex="0">
      <span className="cursor-pointer hover:underline" onClick={onReset}>
        Reset
      </span>
    </div>
  );
};
