import React, {
  useEffect,
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';
import Select from 'react-select';
import { enterPressed } from '../utils/helper';
import { selectFilter } from '../helpers/selectHelper';
import { removeBracketsAndNumbers } from '../helpers/tableHelper';
import { findSquareBreckets } from '../constants/constants';

let formatValues = (propOptions, propValue) => {
  let currentOptions = removeBracketsAndNumbers(propValue).split(', ');
  let values = propOptions.filter((option) =>
    currentOptions.includes(option.value)
  );
  if (values.length !== currentOptions.length) {
    currentOptions.forEach((option) => {
      if (!propOptions.find((ct) => ct.value === option)) {
        values.push({ id: option, label: option, value: option });
      }
    });
  }
  return values;
};

/*
When using closeMenuOnSelect={true} and default react-select behaviour, when user removes value with 'x'
menu is not being closed, so instead of confirming choices on enter, first value from the list is being selected
Because of that we introduced menuIsOpen and handled open status on our own:
 - Menu will open on mouse click, arrow key down, or onInputChanged (user is typing)
 - Menu will be closed when some closeMenuActions is triggered
 */
const MultiselectCellEditor = forwardRef((props, ref) => {
  const [value, setValue] = useState(() => {
    if (props.value) {
      return formatValues(props.values, props.value);
    } else return null;
  });
  //handle first letter lost on typing and enter key
  const [inputValue, setInputValue] = useState(
    props.eventKey && props.eventKey.length === 1 ? props.eventKey : ''
  );
  const [scroll, setScroll] = useState(false);
  const [width] = useState(props.column.actualWidth);
  const refInput = useRef(null);
  const input = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const closeMenuActions = ['remove-value', 'pop-value', 'select-option'];

  useEffect(() => {
    //disable scroll outside of menuList. when scroll happens, finish editing
    if (scroll) {
      props.stopEditing();
    }
  }, [scroll]);

  useEffect(() => {
    refInput.current.focus();
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', handleScrollOutside, true);
    return () => {
      document.removeEventListener('scroll', handleScrollOutside, true);
    };
  }, []);

  const handleScrollOutside = (event) => {
    if (input.current && !input.current.contains(event.target)) {
      setScroll(true);
    }
  };

  const CustomStyle = {
    control: (styles) => ({
      ...styles,
      width: width,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      display: 'none',
    }),
    clearIndicator: (styles) => ({
      ...styles,
      display: 'none',
    }),
  };

  const resolveEnterKeyDownEvent = () => {
    //trigger remove-value event when clicking 'enter' when x is in focus(red)
    let removeItemButton = document.getElementsByClassName(
      'css-1ke7a9t select__multi-value__remove'
    );
    if (removeItemButton.length > 0) {
      let codeTagName =
        removeItemButton[0].parentElement.firstElementChild.innerText;
      let [optionToRemove, newValueArray] = value.reduce(
        (result, element) => {
          result[element.value === codeTagName ? 0 : 1].push(element);
          return result;
        },
        [[], []]
      );
      refInput.current.onChange(newValueArray, {
        action: 'remove-value',
        name: 'multiselect',
        removedValue: optionToRemove[0],
      });
    } else if (!menuIsOpen) {
      props.stopEditing();
    }
  };

  const onInputChanged = (input) => {
    let value = findSquareBreckets.test(input)
      ? removeBracketsAndNumbers(input)
      : input;
    setMenuIsOpen(true);
    setInputValue(value);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value ? value.map((v) => v.id) : [];
      },

      isPopup() {
        return true;
      },
    };
  });

  return (
    <div
      translate="no"
      ref={input}
      onKeyDownCapture={(e) => {
        if (e.code === 'ArrowDown') {
          if (!menuIsOpen) setMenuIsOpen(true);
        } else if (enterPressed(e)) {
          resolveEnterKeyDownEvent();
        }
      }}
      onMouseDownCapture={() => setMenuIsOpen(true)}
    >
      <Select
        name="multiselect"
        ref={refInput}
        defaultValue={value}
        isMulti
        options={props.selectableValues}
        className="basic-multi-select"
        classNamePrefix="select"
        onInputChange={onInputChanged}
        inputValue={inputValue}
        onChange={(options, actionMeta) => {
          if (closeMenuActions.includes(actionMeta.action)) {
            setMenuIsOpen(false);
          }
          setValue(options);
          setInputValue('');
        }}
        filterOption={selectFilter}
        styles={CustomStyle}
        backspaceRemovesValue={true}
        openMenuOnFocus
        menuPlacement="auto"
        menuIsOpen={menuIsOpen}
      />
    </div>
  );
});

MultiselectCellEditor.displayName = 'MultiselectCellEditor';

export default MultiselectCellEditor;
