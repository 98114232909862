import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ColoredButton from '../../common/ColoredButton';
import Select, { createFilter } from 'react-select';
import { Controller, useForm } from 'react-hook-form';
import OutlineButton from '../../common/OutlineButton';
import { digitRgx, MAX_TEXT_LENGTH } from '../../../constants/constants';
import {
  priorityFormSelect,
  RATE_TYPES_SELECT,
} from '../../../constants/jobConstants';
import { getCodeMappingsCount } from '../../../services/mappingDataServices';
import DatePicker from 'react-datepicker';
import moment from 'moment/moment';
import { createClinicJob } from '../../../services/jobServices';
import { getError } from '../../../helpers/errorHelper';
import { formSelectStyle } from '../../../helpers/selectHelper';
import AsyncSelect from 'react-select/async';
import { WindowedMenuList } from 'react-windowed-select';
import { enterPressed } from '../../../utils/helper';
import { isMapper } from '../../../helpers/userHelper';

export default function ClinicJobForm({
  onCancel,
  availablePractices,
  onSave,
}) {
  const [serverError, setServerError] = useState(null);
  const [numberOfCodes, setNumberOfCodes] = useState('-');
  const [numberOfCodesTextColor, setNumberOfCodesTextColor] =
    useState('text-dark-gray');
  const [areButtonsDisabled, disableButtons] = useState(false);
  const { activeUsersOnly } = useSelector((state) => state.jobsTable);
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();
  const assignedUser = watch('assign_to', false);

  useEffect(() => {
    if (!assignedUser || !isMapper(assignedUser)) {
      setValue('hidden_from_mapper', false);
    }
  }, [assignedUser]);

  const submitForm = (data) => {
    //prepare data
    disableButtons(true);
    data.available_at = moment(data.available_at).format('YYYY-MM-DD');
    data.practice_id = data.practice.id;
    data.priority = data.priority.value;
    data.rate_type = data.rate_type.value;
    if (data.assign_to) data.claimed_by = data.assign_to.id;
    delete data.practice;
    delete data.assign_to;
    //save job
    createClinicJob(data)
      .then(async () => {
        await onSave();
        disableButtons(false);
        onCancel();
      })
      .catch((err) => {
        disableButtons(false);
        setServerError(getError(err).description);
      });
  };

  const fetchCodeCount = (data) => {
    getCodeMappingsCount({
      practice_id: [data.id],
      verified: false,
      has_transactions: true,
      without_verification_statuses: true,
      exclude_custom_code_tags: true,
    })
      .then((response) => {
        setNumberOfCodes(response);
        setNumberOfCodesTextColor('text-dark-gray');
      })
      .catch((err) => {
        getError(err);
        setNumberOfCodes('unavailable');
        setNumberOfCodesTextColor('text-dark-gray');
      });
  };

  const filterOptions = (inputValue) => {
    const isValid =
      inputValue && (inputValue.match(digitRgx) || inputValue.length >= 3);
    return isValid
      ? availablePractices.filter((i) =>
          i.label.toLowerCase().includes(inputValue.toLowerCase())
        )
      : [];
  };

  const loadOptions = (inputValue, callback) => {
    callback(filterOptions(inputValue));
  };

  const validateCodes = () => {
    if (numberOfCodes < 1) {
      return 'This clinic has no codes, please select another clinic.';
    } else if (numberOfCodes > 20000) {
      return 'This clinic has more than 20.000 codes, please create multiple custom jobs for it.';
    }
    return true;
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(submitForm)}
        onKeyDown={(e) => {
          enterPressed(e) && e.preventDefault();
        }}
      >
        <div className="relative mb-9">
          <Controller
            id="practice"
            name="practice"
            control={control}
            rules={{
              required: 'Practice is required',
              validate: {
                codes: () => validateCodes(),
              },
            }}
            render={({ field }) => (
              <AsyncSelect
                defaultOptions
                cacheOptions
                loadOptions={loadOptions}
                components={{ MenuList: WindowedMenuList }}
                noOptionsMessage={() => null}
                filterOption={createFilter({ ignoreAccents: false })}
                id="practice"
                inputId="practice-input"
                {...field}
                className="formInput select"
                isDisabled={areButtonsDisabled}
                isClearable={true}
                onChange={(value) => {
                  if (value) {
                    fetchCodeCount(value);
                  }
                  field.onChange(value);
                }}
                styles={formSelectStyle(errors, 'practice')}
                options={availablePractices}
              />
            )}
          />
          <label htmlFor="practice-input" className="formLabel required">
            Practice
          </label>
          {errors.practice?.type === 'required' ? (
            <p className="formError">{errors.practice.message}</p>
          ) : (
            <p className="absolute text-gray-400 mt-1 text-xs min-h-[20px]">
              Estimated count of codes:
              <span className={numberOfCodesTextColor}> {numberOfCodes}</span>
            </p>
          )}
        </div>
        <div data-testid="assign_to_container" className="relative mb-9">
          <Controller
            id="assign_to"
            name="assign_to"
            control={control}
            render={({ field }) => (
              <Select
                components={{ MenuList: WindowedMenuList }}
                filterOption={createFilter({ ignoreAccents: false })}
                id="assign_to"
                inputId="assign_to-input"
                {...field}
                className="formInput select"
                isDisabled={areButtonsDisabled}
                isClearable={true}
                options={activeUsersOnly}
              />
            )}
          />
          <label htmlFor="assign_to-input" className="formLabel">
            Assign to
          </label>
          <p className="absolute text-gray-400 mt-1 text-xs min-h-[20px]">
            Once assigned user can not be changed.
          </p>
        </div>
        {assignedUser && isMapper(assignedUser) && (
          <div className="relative mb-8">
            <input
              id="hidden_from_mapper"
              type="checkbox"
              className="border border-gray rounded-sm bg-white focus:ring-transparent"
              {...register('hidden_from_mapper')}
            />
            <label
              htmlFor="hidden_from_mapper"
              className="ml-1 text-xs text-gray-400"
            >
              Hide from mapper when posted
            </label>
          </div>
        )}
        <div className="relative mb-8">
          <Controller
            name="priority"
            control={control}
            rules={{ required: 'Priority is required' }}
            render={({ field }) => (
              <Select
                {...field}
                inputId="priority-input"
                className="formInput select"
                isDisabled={areButtonsDisabled}
                styles={formSelectStyle(errors, 'priority')}
                options={priorityFormSelect}
              />
            )}
          />
          <label htmlFor="priority-input" className="formLabel required">
            Priority
          </label>
          {errors.priority?.type === 'required' && (
            <p className="formError">{errors.priority.message}</p>
          )}
        </div>
        <div className="relative mb-8">
          <input
            id="project_tracking"
            type="text"
            className="formInput"
            {...register('project_tracking')}
          />
          <label htmlFor="project_tracking" className="formLabel">
            Project Tracking
          </label>
        </div>
        <div className="relative mb-8">
          <input
            id="rate"
            type="number"
            step="0.01"
            className={`formInput required ${
              errors.rate ? 'validationError' : ''
            }`}
            {...register('rate', { required: 'Rate is required' })}
          />
          <label htmlFor="rate" className="formLabel required">
            Rate ($)
          </label>
          {errors.rate?.type === 'required' && (
            <p className="formError">{errors.rate.message}</p>
          )}
        </div>
        <div className="relative mb-8">
          <Controller
            name="rate_type"
            defaultValue={RATE_TYPES_SELECT[0]}
            control={control}
            rules={{ required: 'Rate Type is required' }}
            render={({ field }) => (
              <Select
                {...field}
                inputId="rate-type-input"
                className="formInput select"
                isDisabled={areButtonsDisabled}
                styles={formSelectStyle(errors, 'rate-type')}
                options={RATE_TYPES_SELECT}
              />
            )}
          />
          <label htmlFor="rate-type-input" className="formLabel required">
            Rate Type
          </label>
          {errors.rate_type?.type === 'required' && (
            <p className="formError">{errors.rate_type.message}</p>
          )}
        </div>
        <div className="relative mb-8">
          <Controller
            name="available_at"
            control={control}
            render={({ field }) => (
              <DatePicker
                id="available_at"
                className="formInput"
                wrapperClassName="formDatepicker"
                dateFormat="yyyy-MM-dd"
                onChange={(date) => field.onChange(date)}
                selected={assignedUser ? new Date() : field.value}
                readOnly={assignedUser}
                autoComplete="off"
              />
            )}
          />
          <label htmlFor="available_at" className="formLabel">
            Date Available
          </label>
        </div>
        <div className="relative">
          <textarea
            id="admin_description"
            type="text"
            rows="3"
            className="textarea form"
            {...register('admin_description', {
              maxLength: MAX_TEXT_LENGTH,
            })}
          />
          <label htmlFor="admin_description" className="formAreaLabel">
            Description
          </label>
          {errors.admin_description?.type === 'maxLength' && (
            <p className="absolute text-red-500 w-80 mt-1 text-xs">
              Admin description can have up to {MAX_TEXT_LENGTH} characters
            </p>
          )}
        </div>
        <div className="relative">
          <textarea
            id="admin_notes"
            type="text"
            rows="3"
            className="textarea form"
            {...register('admin_notes', {
              maxLength: MAX_TEXT_LENGTH,
            })}
          />
          <label htmlFor="admin_notes" className="formAreaLabel">
            Admin Notes
          </label>
          {errors.admin_notes?.type === 'maxLength' && (
            <p className="absolute text-red-500 w-80 mt-1 text-xs">
              Admin notes can have up to {MAX_TEXT_LENGTH} characters
            </p>
          )}
        </div>
        <div className="relative mb-12">
          <div className="formSectionTitle">Protocol:</div>
          <div className="formSection">
            <div className="relative w-[30%]">
              <label htmlFor="protocolDhpp" className="formLabel">
                DHPP
              </label>
              <input
                id="protocolDhpp"
                type="text"
                className="formInput inline"
                {...register('vaccine_protocol.dhpp')}
              />
            </div>
            <div className="relative w-[30%]">
              <label htmlFor="protocolFvrcp" className="formLabel">
                FVRCP
              </label>
              <input
                id="protocolFvrcp"
                type="text"
                className="formInput inline"
                {...register('vaccine_protocol.fvrcp')}
              />
            </div>
            <div className="relative w-[30%]">
              <label htmlFor="protocolRabies" className="formLabel">
                Rabies
              </label>
              <input
                id="protocolRabies"
                type="text"
                className="formInput inline"
                {...register('vaccine_protocol.rabies')}
              />
            </div>
          </div>
          <div className="formServerError">{serverError}</div>
          {errors.practice?.type === 'codes' && (
            <p className="formError pt-2">{errors.practice?.message}</p>
          )}
        </div>
        <div className="formButtonsPanel">
          <OutlineButton
            outlineColor="border-gray"
            textColor="text-gray"
            text="Cancel"
            widthClass="w-[120px]"
            heightClass="h-10"
            onClick={onCancel}
            disabled={areButtonsDisabled}
          />
          <ColoredButton
            fillColor="bg-vs-green"
            text="Save"
            widthClass="w-[120px]"
            heightClass="h-10"
            submit={true}
            disabled={areButtonsDisabled}
          />
        </div>
      </form>
    </>
  );
}
