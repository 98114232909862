import { configureStore } from '@reduxjs/toolkit';
import userReducer from '../features/user/userSlice';
import jobReducer from '../features/job/jobSlice';
import modalReducer from '../features/modal/modalSlice';
import tableReducer from '../features/table/tableSlice';
import practiceReducer from '../features/practice/practiceSlice';
import jobsTableReducer from '../features/jobs-table/jobsTableSlice';
import pipelineReducer from '../features/pipeline-status/pipelineStatusSlice';
import groupsReducer from '../features/groups/groupsSlice';
import userConfigReducer from '../features/user-configuration/userConfigSlice';
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
  user: userReducer,
  job: jobReducer,
  modal: modalReducer,
  tableData: tableReducer,
  jobsTable: jobsTableReducer,
  practice: practiceReducer,
  pipelineStatus: pipelineReducer,
  groups: groupsReducer,
  userConfig: userConfigReducer,
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
