import { axiosSecureInstance } from './apiSecure';

export async function getExcludedResources() {
  return await axiosSecureInstance.get('/excluded_resources');
}

export async function saveExcludedResource(data) {
  return await axiosSecureInstance.post('/excluded_resources', data);
}

export async function deleteExcludedResource(excludedResourceId) {
  return await axiosSecureInstance.delete(
    `/excluded_resources/${excludedResourceId}`
  );
}
