import React, { memo } from 'react';

const PageSize = memo(function PageSize({
  onPageSizeChanged,
  largePageSize = false,
}) {
  return (
    <span className="row-per-page-container">
      <select
        onChange={onPageSizeChanged}
        id="page-size"
        style={{ margin: '0px', paddingLeft: '0px' }}
      >
        <option value="50">50</option>
        <option value="100">100</option>
        <option value="200">200</option>
        {largePageSize && <option value="500">500</option>}
        {largePageSize && <option value="1000">1000</option>}
      </select>
    </span>
  );
});

export default PageSize;
