import React, { memo } from 'react';

const OutlineIconButton = memo(function OutlineIconButton({
  outlineColor = 'border-gray',
  textColor = 'text',
  widthClass = 'w-[120px]',
  heightClass = '',
  bgClass = '',
  onClick,
  text,
  uppercase,
  disabled,
  icon,
  padding = 'px-3 py-1',
  margin = 'mx-1',
}) {
  return (
    <button
      className={`text-13 rounded-sm hover:bg-gray-300 focus:outline-none font-normal
            ${padding}
            ${margin}
            ${textColor}
            ${outlineColor}
            ${uppercase ? 'uppercase' : ''}
            ${widthClass}
            ${heightClass}
            ${disabled ? 'bg-gray-300 text-gray-350' : 'border border-solid'}
            ${bgClass}`}
      type="button"
      onClick={onClick}
      disabled={disabled ? disabled : false}
    >
      {icon && (
        <img
          className="inline mr-2"
          width="16"
          height="16"
          src={icon}
          alt="menu-close"
        />
      )}
      {text}
    </button>
  );
});

export default OutlineIconButton;
