import { createSlice } from '@reduxjs/toolkit';
import { loadPractices } from '../table/tableActions';
import { logout, resetState } from '../user/userActions';

const initialState = {
  practices: null,
};
export const practiceSlice = createSlice({
  name: 'practice',
  initialState: initialState,
  extraReducers: {
    [loadPractices.fulfilled]: (state, { payload }) => {
      state.practices = payload;
    },
    [logout]: (state) => {
      state.practices = null;
    },
    [resetState]: () => initialState,
  },
});

export default practiceSlice.reducer;
