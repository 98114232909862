import React from 'react';
import { getUserInitials } from '../../helpers/userHelper';

export default function UserIdIcon({ userInfo }) {
  return userInfo ? (
    <div className="flex items-center text-sm">
      <div className="group relative">
        <div className="ml-4 text-sky-blue font-extrabold">
          {getUserInitials(userInfo)}
        </div>
        <div className="status-tooltip">
          <div className="status-tooltip-arrow" />
          <div className="font-semibold">{`Claimed by ${userInfo?.first_name}  ${userInfo?.last_name}`}</div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
}
