import { memo } from 'react';
import { Dialog } from '@headlessui/react';
import OutlineButton from './OutlineButton';
import ColoredButton from './ColoredButton';
import warningIcon from '../../assets/icons/warning.svg';

const ConfirmationModal = memo(function ConfirmationModal({
  isOpen,
  onClose,
  title,
  description,
  importantText = null,
  text,
  buttonText,
  operationType = 'info',
  onButtonClick,
  buttonsDisabled = false,
  showCancel = true,
  overrideDefaultPosition = null,
}) {
  let fillColorClass,
    border,
    hoverColorClass = '';
  switch (operationType) {
    case 'warning':
      fillColorClass = 'bg-orange';
      border = 'border border-solid border-orange outline-none';
      hoverColorClass = 'hover:bg-orange-hover';
      break;
    case 'danger':
      fillColorClass = 'bg-red';
      border = 'border border-solid border-red outline-none';
      hoverColorClass = 'hover:bg-red-default-hover';
      break;
  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div
          className={`flex ${
            overrideDefaultPosition ? overrideDefaultPosition : 'min-h-full'
          } items-center justify-center p-4 text-center`}
        >
          <Dialog.Panel className="max-w-md transform overflow-hidden rounded-2xl bg-white px-14 py-10 text-left align-middle shadow-xl transition-all">
            <Dialog.Title
              as="h3"
              className="text-center text-lg font-medium leading-6 font-bold text"
            >
              {operationType === 'warning' && (
                <img
                  className="mx-auto mb-5"
                  src={warningIcon}
                  alt="warning-icon"
                />
              )}
              {title}
            </Dialog.Title>
            <Dialog.Description>{description}</Dialog.Description>
            <div className="my-4 text-center">
              {operationType === 'warning' && importantText && (
                <p className="inline text-sm text-red-important whitespace-pre-wrap">
                  {importantText}
                </p>
              )}
              <p className="inline text-sm text-gray-400 whitespace-pre-wrap">
                {text}
              </p>
            </div>
            <div className="text-center mt-6">
              {showCancel && (
                <OutlineButton
                  text="Cancel"
                  heightClass="h-10"
                  onClick={onClose}
                  disabled={buttonsDisabled}
                />
              )}
              <ColoredButton
                text={buttonText}
                heightClass="h-10"
                fillColorClass={fillColorClass}
                border={border}
                hoverColorClass={hoverColorClass}
                onClick={onButtonClick}
                disabled={buttonsDisabled}
              />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
});

export default ConfirmationModal;
