import React, { useState } from 'react';
import WindowedSelect, { createFilter } from 'react-windowed-select';

export const CustomMultiSelect = ({ options, value, onValueChange }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <WindowedSelect
      options={options}
      value={value}
      onChange={onValueChange}
      filterOption={createFilter({ ignoreAccents: false })}
      isMulti
      menuPosition="fixed"
      closeMenuOnSelect={false}
      isClearable={false}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      onKeyDown={(e) => isMenuOpen && e.stopPropagation()}
      className="w-full p-2.5"
    />
  );
};
