import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from 'react';

const PseudoTextEditor = forwardRef((props, ref) => {
  const [value] = useState(props.value);
  const refInput = useRef(null);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      isCancelAfterEnd() {
        return true;
      },
    };
  });

  return (
    <textarea
      ref={refInput}
      className="pseudo-edit-textarea"
      value={value || ''}
      readOnly={true}
    />
  );
});

PseudoTextEditor.displayName = 'PseudoTextEditor';

export default PseudoTextEditor;
