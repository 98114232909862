import { memo } from 'react';

const ProtocolInfo = memo(function ProtocolInfo({ protocol, hidden }) {
  return (
    <div className={`${hidden ? 'hidden' : ''}`}>
      <div className="flex">
        <div className="protocol-info">DHPP - {protocol?.dhpp || ''}</div>
        <div className="protocol-info">FVRCP - {protocol?.fvrcp || ''}</div>
        <div className="protocol-info">Rabies - {protocol?.rabies || ''}</div>
      </div>
    </div>
  );
});

export default ProtocolInfo;
