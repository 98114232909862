export const MAPPER_ROLE = 'mapper';
export const ADMIN_ROLE = 'admin';
export const SUPER_ADMIN_ROLE = 'super_admin';
export const VSO_TEAM_ROLE = 'vso_team';

export const ALL_ROLES = [
  SUPER_ADMIN_ROLE,
  ADMIN_ROLE,
  MAPPER_ROLE,
  VSO_TEAM_ROLE,
];
export const ADMIN_ROLES = [SUPER_ADMIN_ROLE, ADMIN_ROLE];
export const JOBS_ROLES = [SUPER_ADMIN_ROLE, ADMIN_ROLE, MAPPER_ROLE];

export const ROLE_NAMES = {
  mapper: 'Mapper',
  admin: 'Admin',
  super_admin: 'Super Admin',
  vso_team: 'VSO Team',
};

export const ROLE_NAMES_LIST = [
  { id: 'mapper', name: 'Mapper' },
  { id: 'admin', name: 'Admin' },
  { id: 'super_admin', name: 'Super Admin' },
  { id: 'vso_team', name: 'VSO Team' },
];
