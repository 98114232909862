import React, { memo } from 'react';

const JobCodeCountInfo = memo(function JobCodeCountInfo({
  totalCodeCount,
  mappedCodeCount,
}) {
  return (
    <div className="row-per-page-container ml-20">
      <span className="px-2 bg-vs-green-300 mx-2 rounded-lg text-black">
        {mappedCodeCount || 0}
      </span>
      of{' '}
      <span className="px-2 bg-gray-300 mx-2 rounded-lg text-black">
        {totalCodeCount || '-'}
      </span>{' '}
      codes mapped
    </div>
  );
});

export default JobCodeCountInfo;
