import React, { Fragment } from 'react';
import { Tab } from '@headlessui/react';
import PipelineStatuses from './pipeline-status/PipelineStatuses';
import ExcludedResources from './excluded-practices/ExcludedResources';

export default function Config() {
  const ConfigTab = ({ title }) => {
    return (
      <Tab as={Fragment}>
        {({ selected }) => (
          <button
            className={`px-4 py-2 outline-none ${
              selected
                ? 'text-bgGreen border-b-2 border-bgGreen'
                : 'text-disabled-gray'
            }`}
          >
            {title}
          </button>
        )}
      </Tab>
    );
  };

  return (
    <Tab.Group as="div" className="m-6">
      <Tab.List>
        <ConfigTab title="Pipeline Statuses" />
        <ConfigTab title="Excluded Practices" />
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel>
          <PipelineStatuses />
        </Tab.Panel>
        <Tab.Panel>
          <ExcludedResources />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
