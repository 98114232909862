import { Dialog } from '@headlessui/react';

export default function FormModal({ openState, onClose, title, form }) {
  return (
    <Dialog open={openState} onClose={() => onClose(false)}>
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex items-center justify-center p-4 text-center">
          <Dialog.Panel className="w-400 rounded-2xl bg-white px-14 py-10 text-left align-middle shadow-xl flex-col">
            <Dialog.Title
              as="h3"
              className="text-center text-lg font-medium leading-6 font-bold text mb-8"
            >
              {title}
            </Dialog.Title>
            {form}
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
}
