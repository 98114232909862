import {
  code_tags_ids,
  mapped_at,
  mapped_by,
  pms_code_vetsuccess_id,
} from '../constants/constants';

function getCodeTagFieldValue(codeTags, updated_resource) {
  return codeTags
    .filter((codeTag) => updated_resource.code_tag_ids.includes(codeTag.id))
    .map((codeTag) => codeTag.id);
}

export function updateTableData(
  nodesToUpdate,
  nodesUpdates,
  codeTags,
  updatedColumns
) {
  let updatedVsIds = nodesUpdates.map(
    (update) => update.pms_code_vetsuccess_id
  );

  nodesToUpdate.forEach((nodeToUpdate) => {
    let nodeData = JSON.parse(JSON.stringify(nodeToUpdate.data));
    let serverUpdatedColumns = [mapped_by.field, mapped_at.field];
    if (updatedVsIds.includes(nodeData[pms_code_vetsuccess_id.field])) {
      let updated_resource = nodesUpdates.find(
        (nodeUpadate) =>
          nodeUpadate.pms_code_vetsuccess_id ===
          nodeData[pms_code_vetsuccess_id.field]
      );
      updatedColumns.concat(serverUpdatedColumns).forEach((updatedColumn) => {
        if (updatedColumn === code_tags_ids.field) {
          nodeData[code_tags_ids.field] = getCodeTagFieldValue(
            codeTags,
            updated_resource
          );
        } else {
          nodeData[updatedColumn] = updated_resource[updatedColumn];
        }
      });
      nodeToUpdate.setData(nodeData);
    }
  });
}
