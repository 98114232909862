import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { loadUsers } from '../../../features/table/tableActions';
import edit from '../../../assets/icons/edit.svg';
import {
  JOB_STATUSES,
  JOB_STATUS_COMPLETED,
  JOB_STATUS_COMPLETION_PENDING,
  JOB_TYPES,
  RATE_TYPES,
  RATE_TYPE_PER_HOUR,
  mappableJobStatuses,
  RATE_TYPE_PER_CODE,
} from '../../../constants/jobConstants';
import { editJobDetailsClicked } from '../../../features/modal/modalSlice';
import ErrorAlert from '../../common/ErrorAlert';
import { isAdmin, isMapper } from '../../../helpers/userHelper';
import {
  clinicDetailsAllowedForUser,
  isClinicJob,
  isCustomJob,
  jobBelongsToUser,
} from '../../../helpers/jobsHelper';
import { getElementById } from '../../../helpers/commonHelper';
import { updateJobDetails } from '../../../services/jobServices';
import { getError } from '../../../helpers/errorHelper';
import { MAX_TEXT_LENGTH } from '../../../constants/constants';
import { jobNotesSaved } from '../../../features/job/jobActions';
import close from '../../../assets/icons/close.svg';
import MinutesSpent from '../../table/MinutesSpent';
import { hoursEditableForUser } from '../../../helpers/jobsTableHelper';

const borderClasses = {
  high: 'border-red',
  medium: 'border-orange',
  low: 'border-blue',
};

export default function JobDetails({ mappingJobDetails, onClose }) {
  const dispatch = useDispatch();
  const [editJobNotesOpen, setEditJobNotesOpen] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [jobNotesValidationError, setJobNotesValidationError] = useState(null);
  const [jobNotesServerError, setJobNotesServerError] = useState(null);
  const [jobMappedTimeServerError, setJobMappedTimeServerError] =
    useState(null);
  const { userInfo } = useSelector((state) => state.user);
  const { users } = useSelector((state) => state.tableData);
  useEffect(() => {
    if (!users && isAdmin(userInfo)) {
      dispatch(loadUsers());
    }
  }, [users]);

  const loadDetails = users !== null || !isAdmin(userInfo);

  return (
    <div id="job-details" className="flex flex-col h-full flex-grow-1">
      {loadDetails && (
        <>
          <div className="flex max-w-2xl mb-4 items-center">
            <div className="flex flex-1">
              <div className="text-lg font-medium ">{`Job details (#${mappingJobDetails.id})`}</div>
              {isAdmin(userInfo) && (
                <img
                  className="ml-2 cursor-pointer "
                  src={edit}
                  width="30"
                  height="30"
                  alt="edit-icon"
                  onClick={() => {
                    dispatch(editJobDetailsClicked());
                  }}
                />
              )}
            </div>
            <img
              className="cursor-pointer "
              onClick={() => onClose()}
              width="20"
              height="20"
              src={close}
              alt="menu-close"
            />
          </div>
          <div
            data-testid="priority-color"
            className={`flex flex-col max-w-2xl py-2 px-4 ml-5 border-l-4 ${
              borderClasses[mappingJobDetails.priority]
            }`}
          >
            <div className="job-details-row">
              <div className="job-details-row-title">Available From:</div>
              <div>{mappingJobDetails.available_at}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Priority:</div>
              <div className="capitalize">{mappingJobDetails.priority}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Job Type:</div>
              <div>{JOB_TYPES[mappingJobDetails.job_type].name}</div>
            </div>
            {isClinicJob(mappingJobDetails) &&
              !clinicDetailsAllowedForUser(mappingJobDetails, userInfo) && (
                <div className="job-details-row">
                  <div className="job-details-row-title">Clinic Number:</div>
                  <div>{mappingJobDetails.practice_id}</div>
                </div>
              )}
            <div className="job-details-row">
              <div className="job-details-row-title">Project tracking:</div>
              <div>{mappingJobDetails.project_tracking}</div>
            </div>
            {isCustomJob(mappingJobDetails) && (
              <div className="job-details-row">
                <div className="job-details-row-title">
                  Contains Excluded Codes:
                </div>
                <div>
                  {mappingJobDetails.include_excluded_codes ? 'Yes' : 'No'}
                </div>
              </div>
            )}
            {clinicDetailsAllowedForUser(mappingJobDetails, userInfo) && (
              <>
                <div className="job-details-header">Clinic Details</div>
                <div className="job-details-row">
                  <div className="job-details-row-title">Number:</div>
                  <div>{mappingJobDetails.practice_id}</div>
                </div>
                <div className="job-details-row">
                  <div className="job-details-row-title">Name:</div>
                  <div>{mappingJobDetails.practice_name}</div>
                </div>
                <div className="job-details-row">
                  <div className="job-details-row-title">City:</div>
                  <div>{mappingJobDetails.practice_city}</div>
                </div>
                <div className="job-details-row">
                  <div className="job-details-row-title">State:</div>
                  <div>{mappingJobDetails.practice_state}</div>
                </div>
              </>
            )}
            <div className="job-details-header">Job Description</div>
            <div className="job-details-row">
              <div>{mappingJobDetails.admin_description || '/'}</div>
            </div>
            <div className="job-details-header">Protocol</div>
            <div className="job-details-row">
              <div className="job-details-row-title">DHPP:</div>
              <div>
                {mappingJobDetails.vaccine_protocol &&
                  mappingJobDetails.vaccine_protocol.dhpp}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">FVRCP:</div>
              <div>
                {mappingJobDetails.vaccine_protocol &&
                  mappingJobDetails.vaccine_protocol.fvrcp}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Rabies:</div>
              <div>
                {mappingJobDetails.vaccine_protocol &&
                  mappingJobDetails.vaccine_protocol.rabies}
              </div>
            </div>
            <div className="job-details-header">Rates</div>
            <div className="job-details-row">
              <div className="job-details-row-title">Total Code Count:</div>
              <div>{mappingJobDetails.unverified_code_count}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Rate:</div>
              <div>{`$${mappingJobDetails.rate}`}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Rate Type:</div>
              <div>{RATE_TYPES[mappingJobDetails.rate_type]}</div>
            </div>
            {mappingJobDetails.rate_type === RATE_TYPE_PER_HOUR && (
              <div className="job-details-row time-spent">
                <div className="job-details-row-title">Time Spent:</div>
                <MinutesSpent
                  jobDetails={mappingJobDetails}
                  setAlertError={setJobMappedTimeServerError}
                  editEnabled={
                    isAdmin(userInfo) &&
                    hoursEditableForUser(
                      mappingJobDetails.status,
                      mappableJobStatuses.concat([
                        JOB_STATUS_COMPLETED,
                        JOB_STATUS_COMPLETION_PENDING,
                      ]),
                      mappingJobDetails.invoiced
                    )
                  }
                />
              </div>
            )}
            {mappingJobDetails.rate_type === RATE_TYPE_PER_CODE &&
              mappingJobDetails.status === JOB_STATUS_COMPLETED && (
                <div className="job-details-row">
                  <div className="job-details-row-title">
                    Mapped Code Count:
                  </div>
                  <div>{mappingJobDetails.mapped_code_count}</div>
                </div>
              )}
            <div className="job-details-header">Actions</div>
            <div className="job-details-row">
              <div className="job-details-row-title">Claimed by:</div>
              <div>
                {/*handle users not being available for mappers*/}
                {isMapper(userInfo) &&
                  jobBelongsToUser(mappingJobDetails, userInfo.id) &&
                  userInfo.displayName}
                {isAdmin(userInfo) &&
                  mappingJobDetails.claimed_by &&
                  getElementById(users, mappingJobDetails.claimed_by).value}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Date Claimed:</div>
              <div>
                {mappingJobDetails.claimed_at
                  ? moment(mappingJobDetails.claimed_at).format('YYYY-MM-DD')
                  : null}
              </div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Date Completed:</div>
              <div>{mappingJobDetails.completed_at}</div>
            </div>
            <div className="job-details-row">
              <div className="job-details-row-title">Status:</div>
              <div>{JOB_STATUSES[mappingJobDetails.status]}</div>
            </div>
            {isAdmin(userInfo) && (
              <div>
                <div className="job-details-header">Admin Notes</div>
                <div className="job-details-row notes">
                  <div>{mappingJobDetails.admin_notes || '/'}</div>
                </div>
              </div>
            )}
            {(jobBelongsToUser(mappingJobDetails, userInfo.id) ||
              isAdmin(userInfo)) && (
              <>
                <div className="job-details-header">
                  <>Notes</>
                  {!editJobNotesOpen ? (
                    <img
                      className="ml-2 cursor-pointer"
                      src={edit}
                      width="30"
                      height="30"
                      alt="edit-notes-icon"
                      onClick={() => {
                        setEditJobNotesOpen(true);
                        setJobNotesValidationError(null);
                        setJobNotesServerError(null);
                      }}
                    />
                  ) : (
                    <>
                      <div
                        className="ml-2 cursor-pointer cancel-button"
                        onClick={() => {
                          setEditJobNotesOpen(false);
                          setJobNotesValidationError(null);
                          setJobNotesServerError(null);
                        }}
                      />
                      <div
                        className="ml-2 cursor-pointer approve-button"
                        onClick={() => {
                          let notes = document.getElementById('notes').value;
                          if (notes && notes.length > 1000) {
                            setJobNotesValidationError(
                              `Notes can have up to ${MAX_TEXT_LENGTH} characters`
                            );
                          } else {
                            setJobNotesValidationError(null);
                            updateJobDetails(mappingJobDetails.id, {
                              notes: document.getElementById('notes').value,
                            })
                              .then((response) => {
                                dispatch(jobNotesSaved(response.data));
                                setEditJobNotesOpen(false);
                                setJobNotesServerError(null);
                              })
                              .catch((err) => {
                                setJobNotesServerError(
                                  getError(err).description
                                );
                                setEditJobNotesOpen(true);
                              });
                          }
                        }}
                      />
                    </>
                  )}
                </div>
                {editJobNotesOpen ? (
                  <>
                    <textarea
                      id="notes"
                      rows="4"
                      className="textarea text-sm peer resize-none"
                      defaultValue={mappingJobDetails.notes}
                    />
                  </>
                ) : (
                  <div className="job-details-row notes">
                    <div className="overflow-hidden">
                      {mappingJobDetails.notes || '/'}
                    </div>
                  </div>
                )}
                {jobNotesValidationError && (
                  <>
                    <p className="job-details-error">
                      {jobNotesValidationError}
                    </p>
                  </>
                )}
                {jobNotesServerError && (
                  <>
                    <p className="job-details-error">{jobNotesServerError}</p>
                  </>
                )}
                {jobMappedTimeServerError && (
                  <>
                    <p className="job-details-error">
                      {jobMappedTimeServerError}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}

      {alertError && (
        <ErrorAlert
          isOpen={true}
          error={alertError}
          onButtonClick={() => setAlertError(null)}
          onClose={() => setAlertError(null)}
        />
      )}
    </div>
  );
}
