import { useNavigate } from 'react-router-dom';
import logo from '../images/mapping_site_logo.png';

export default function ResetPassword() {
  let navigate = useNavigate();

  return (
    <div
      id="pageContainer"
      className="h-full w-full flex items-center justify-center bg-bgGreen"
    >
      <div
        id="resetPassWrapper"
        className="rounded-sm shadow-basic w-full max-w-md reset-pass-window bg-white"
      >
        <img className="mx-auto w-16 h-14" src={logo} alt="Mapping App" />
        <div className="mb-6 mt-8 text-center font-light text-lg">
          Reset password
        </div>
        <div className="text-center text-mg text-15 text-gray-400">
          Please contact admin <br /> to reset your password.
        </div>
        <div className="text-center">
          <button
            className="text-13 py-1 mx-1 px-3 rounded-sm h-10 mt-7 bg-purple-vs font-medium text-white w-[100px] hover:bg-purple-vs-hover"
            onClick={() => navigate('/login')}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
}
