import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ResetFilterButton } from './ResetFilterButton';
import { SelectInput } from './SelectInput';
import { applyFiltersOnKeyPress } from '../../../helpers/tableFiltersHelper';

export default forwardRef((props, ref) => {
  const [filterValue, setFilterValue] = useState(null);
  const [filterOperator, setFilterOperator] = useState(
    props.colDef.filterParams.values[0]
  );

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        const { api, colDef, column, columnApi, context } = props;
        const { node } = params;

        const value = props.valueGetter({
          api,
          colDef,
          column,
          columnApi,
          context,
          data: node.data,
          getValue: (field) => node.data[field],
          node,
        });

        return filterPass(value, filterOperator, filterValue);
      },

      isFilterActive() {
        return filterValue !== null && filterValue !== '';
      },

      getModel() {
        if (!this.isFilterActive()) {
          return null;
        }

        return {
          value: filterValue,
          operator: filterOperator.value,
          operatorLabel: filterOperator.label.toLowerCase(),
          type: 'number',
          label: props.colDef.headerName,
        };
      },

      setModel(model) {
        setFilterValue(
          model === null || model === undefined ? null : model.value
        );
      },
    };
  });

  const filterPass = (value, filterOperator, filterValueString) => {
    const filterValue = Number(filterValueString);
    switch (filterOperator.value) {
      case 'eq':
        return value === filterValue;
      case 'lt':
        return value < filterValue;
      case 'lte':
        return value <= filterValue;
      case 'gt':
        return value > filterValue;
      case 'gte':
        return value >= filterValue;
      case 'not':
        return value !== filterValue;
      default:
        return false;
    }
  };

  const resetFilter = () => {
    if (filterValue) {
      setFilterValue(null);
      setFilterOperator(props.colDef.filterParams.values[0]);
    }
  };

  return (
    <div
      id="table-number-filter"
      onKeyDown={({ key }) => applyFiltersOnKeyPress(key, props)}
    >
      <ResetFilterButton onReset={resetFilter} />
      <SelectInput
        type="number"
        options={props.colDef.filterParams.values}
        selectValue={filterOperator}
        onSelectChange={setFilterOperator}
        inputValue={filterValue}
        onInputChange={setFilterValue}
      />
    </div>
  );
});
